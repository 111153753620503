import { FloatButton, Layout, theme } from 'antd';
import Breadcrumb from 'src/components/v1/Breadcrumb';
import Footer from 'src/components/v1/Footer';
import Header from 'src/components/v1/Header';
import Sidebar from 'src/components/v1/Sidebar';

interface Props {
  children: React.ReactNode;
}

const { Content } = Layout;

export default function MainLayout({ children }: Props) {
  const {
    token: { colorBgContainer }
  } = theme.useToken();

  return (
    <Layout hasSider>
      <Sidebar colorBgContainer={colorBgContainer} />
      <Layout className='site-layout'>
        <Header colorBgContainer={colorBgContainer} />
        <Content style={{ margin: '0 16px' }}>
          <Breadcrumb />
          <div style={{ padding: 24, marginBottom: 16, minHeight: 360, background: colorBgContainer }}>{children}</div>
        </Content>
        <Footer colorBgContainer={colorBgContainer} />
        <FloatButton.BackTop />
      </Layout>
    </Layout>
  );
}
