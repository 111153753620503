import { useMutation } from '@tanstack/react-query';
import { Button, Form, Input, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { changePassword } from 'src/apis/password.api';
import urlPath from 'src/constants/urlPath/urlPath';
import { IPassword } from 'src/types/password.type';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialFormState: IPassword = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: ''
};

const ChangePassword = ({ open, setOpen }: Props) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const [formState, setFormState] = useState<IPassword>(initialFormState);

  const navigate = useNavigate();

  const changePasswordMutation = useMutation({
    mutationFn: (password: IPassword) => changePassword(password as IPassword)
  });

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
    setFormState(initialFormState);
  };

  const handleSubmit = () => {
    form.validateFields().then((_) => {
      changePasswordMutation.mutate(formState, {
        onSuccess: () => {
          navigate(urlPath.signoutOidc.location);
        }
      });
    });
  };

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      title={t('action.changePassword')}
      maskClosable={false}
      style={{ maxWidth: 500 }}
      onOk={handleSubmit}
      footer={[
        <Button key='back' onClick={handleCancel}>
          {t('action.cancel')}
        </Button>,
        <Button key='submit' type='primary' onClick={handleSubmit} loading={changePasswordMutation.isLoading}>
          {t('action.submit')}
        </Button>
      ]}
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        layout='horizontal'
        fields={[
          {
            name: 'oldPassword',
            value: formState?.oldPassword
          },
          {
            name: 'newPassword',
            value: formState?.newPassword
          },
          {
            name: 'confirmPassword',
            value: formState?.confirmPassword
          }
        ]}
      >
        <Form.Item
          label={t('columnTitle.password.oldPassword')}
          name={'oldPassword'}
          rules={[
            {
              required: true,
              message: t('required.password.oldPassword').toString()
            }
          ]}
        >
          <Input.Password
            onChange={(e) =>
              setFormState((prev) => ({
                ...prev,
                oldPassword: e.target.value
              }))
            }
          />
        </Form.Item>
        <Form.Item
          label={t('columnTitle.password.newPassword')}
          name={'newPassword'}
          rules={[
            {
              required: true,
              message: ''
            },
            () => ({
              validator(_, value: string) {
                if (value.length <= 0) {
                  return Promise.reject(t('required.password.newPassword'));
                }
                if (value.length < 8) {
                  return Promise.reject(t('required.password.character'));
                }
                if (!/[a-z]/.test(value)) {
                  return Promise.reject(t('required.password.lowerCaseLetter'));
                }
                if (!/[A-Z]/.test(value)) {
                  return Promise.reject(t('required.password.upperCaseLetter'));
                }
                if (!/[0-9]/.test(value)) {
                  return Promise.reject(t('required.password.digit'));
                }
                if (!/[^a-zA-Z0-9]/.test(value)) {
                  return Promise.reject(t('required.password.specialCharacter'));
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <Input.Password
            onChange={(e) =>
              setFormState((prev) => ({
                ...prev,
                newPassword: e.target.value
              }))
            }
          />
        </Form.Item>
        <Form.Item
          label={t('columnTitle.password.confirmPassword')}
          name={'confirmPassword'}
          rules={[
            {
              required: true,
              message: t('required.password.confirmPassword').toString()
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('notification.error.noMatchPassword').toString()));
              }
            })
          ]}
        >
          <Input.Password
            onChange={(e) =>
              setFormState((prev) => ({
                ...prev,
                confirmPassword: e.target.value
              }))
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePassword;
