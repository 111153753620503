import type { ILead, LeadAdd, LeadAssign } from 'src/types/crm/lead.type';
import type { FlatItem } from 'src/types/elements.type';
import type { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'crm/leads';

export const getLeads = async (page: number, pageSize: number, orderBy?: string, searchCondition?: string) => {
  return await http.get<PagedList<ILead>>(controller, {
    params: {
      searchCondition,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getLead = async (id: number) => {
  return await http.get<ILead>(`${controller}/${id}`);
};

export const getLeadsDropdown = async () => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
};

export const addLead = async (lead: LeadAdd) => {
  return await http.post<ILead>(controller, lead);
};

export const updateLead = async (id: number, lead: ILead) => {
  return await http.put<ILead[]>(`${controller}/${id}`, lead);
};

export const deleteLead = async (id: number) => {
  await http.delete(`${controller}/${id}`);
};

export const assignLead = async (leadAssign: LeadAssign) => {
  return await http.post(`${controller}/assign`, leadAssign);
};
