import { Input } from 'antd';
import { debounce } from 'lodash';
import { useContext, useMemo } from 'react';
import { TicketContext } from 'src/contexts/ticket.context';
import './searchTicket.scss';
const { Search } = Input;
interface Props {
  placeholder: string;
  className: string;
}

const SearchTicket = ({ placeholder, className }: Props) => {
  const { setSearchTerm } = useContext(TicketContext);

  const debouncedSearch = useMemo(() => debounce((term) => setSearchTerm(term.trim()), 2000), [setSearchTerm]);
  return (
    <Search
      placeholder={placeholder}
      className={`search ${className}`}
      onChange={(e) => debouncedSearch(e.target.value)}
      onSearch={(value) => setSearchTerm(value)}
    />
  );
};

export default SearchTicket;
