import { Select, SelectProps } from 'antd';
import { ticketFieldSort } from 'src/constants/ticketFieldSort';

export default function TicketSortSelect({ value, onChange, style, allowClear = true, placeholder }: SelectProps) {
  return (
    <Select
      allowClear={allowClear}
      style={{ width: '100%', ...style }}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      options={ticketFieldSort.map((data) => ({
        value: data.code,
        label: data.name
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
    />
  );
}
