import type { FlatItem } from 'src/types/elements.type';
import { ITarget, TargetAdd } from 'src/types/erp/target.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'helpdesk/type-of-targets';

export const getTargetsDropdown = async () => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
};

export const getTargets = async (
  sequenceStatus: string,
  searchCondition: string | undefined,
  page: number,
  pageSize: number,
  orderBy?: string
) => {
  return await http.get<PagedList<ITarget>>(controller, {
    params: {
      sequenceStatus,
      searchCondition,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getTarget = async (id: number) => {
  return await http.get<ITarget>(`${controller}/${id}`);
};

export const addTarget = async (target: TargetAdd) => {
  return await http.post(controller, target);
};

export const updateTarget = async (id: number, target: TargetAdd) => {
  return await http.put(`${controller}/${id}`, target);
};

export const deleteTarget = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};
