import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { CSSProperties } from 'react';
import { getUnPaidOvertime } from 'src/apis/hr_ticket/overtimeTicket.api';

type Props = {
  value?: string[];
  style?: CSSProperties | undefined;
  allowClear: boolean | undefined;
  onChange: (value: string[]) => void;
  employeeId: number;
  disabled: boolean;
};

export default function UnpaidOvertimeSelect({ value, onChange, style, allowClear, employeeId, disabled }: Props) {
  const unpaidOvertimesQuery = useQuery({
    queryKey: ['unpaidOvertimesDropdown', employeeId],
    queryFn: () => getUnPaidOvertime(employeeId),
    staleTime: 60 * 1000
  });

  return (
    <Select
      disabled={disabled}
      allowClear={allowClear}
      value={unpaidOvertimesQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      mode='multiple'
      maxTagCount='responsive'
      options={unpaidOvertimesQuery.data?.data.map((data) => ({ value: JSON.stringify(data), label: data.code }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={unpaidOvertimesQuery.isFetching}
    />
  );
}
