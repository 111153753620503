import { DatePicker as Date, DatePickerProps } from 'antd';

export default function DatePicker({
  style,
  disabled,
  allowClear = true,
  format = 'DD/MM/YYYY',
  value,
  onChange,
  disabledDate,
  placeholder
}: DatePickerProps) {
  return (
    <Date
      style={{ width: '100%', ...style }}
      disabled={disabled}
      placeholder={placeholder}
      allowClear={allowClear}
      format={format}
      value={value}
      onChange={onChange}
      disabledDate={disabledDate}
    />
  );
}
