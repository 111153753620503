import { INotification, NotificationParams } from 'src/types/notification.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'helpdesk/notifications';

export const getNotifications = async (params: NotificationParams) =>
  await http.get<PagedList<INotification>>(controller, {
    params: params
  });

export const markNotificationsAsRead = async (notificationId?: string) =>
  await http.put(`${controller}/mark-read?notificationId=${notificationId ?? ''}`);

export const markAllNotificationsAsRead = async () => await http.put(`${controller}/mark-read/all`);
