import urlPath from 'src/constants/urlPath/urlPath';
import type { INotificationType } from 'src/types/helpdesk/notificationType';
import type { UserInfo } from 'src/types/master_data/employee.type';
import { DepartmentEn } from './department.enum';

const result = localStorage.getItem('userInfo');
const userInfo: UserInfo = result ? JSON.parse(result) : null;

export const NOTIFICATION_TYPE: INotificationType[] = [
  //#region TICKET
  {
    key: 'TKA',
    title: 'Ticket - Phê duyệt',
    queryFn: 'tickets',
    returnUrl: urlPath.approval.location
  },
  {
    key: 'TKAR',
    title: 'Ticket - Từ chối',
    queryFn: 'tickets',
    returnUrl: urlPath.myTicket.location
  },
  {
    key: 'TKC',
    title: 'Ticket - Xác nhận',
    queryFn: 'tickets',
    returnUrl: urlPath.accept.location
  },
  {
    key: 'TKCR',
    title: 'Ticket - Từ chối xác nhận',
    queryFn: 'tickets',
    returnUrl: urlPath.myTicket.location
  },
  {
    key: 'TKH',
    title: 'Ticket - Xử lý',
    queryFn: 'tickets',
    returnUrl: urlPath.solution.location
  },
  {
    key: 'TKAS',
    title: 'Ticket - Phân công xử lý',
    queryFn: 'tickets',
    returnUrl: urlPath.handle.location
  },
  {
    key: 'TKAD',
    title: 'Ticket - Phê duyệt',
    queryFn: 'tickets',
    returnUrl: urlPath.myTicket.location
  },
  {
    key: 'TKD',
    title: 'Ticket - Hoàn thành',
    queryFn: 'tickets',
    returnUrl: urlPath.myTicket.location
  },
  {
    key: 'CANCEL_APPROVAL',
    title: 'Ticket - Thu hồi duyệt',
    queryFn: 'tickets',
    returnUrl: urlPath.approval.location
  },
  {
    key: 'TKSC',
    title: 'Ticket - Từ chối giải pháp',
    queryFn: 'tickets',
    returnUrl: urlPath.solution.location
  },
  {
    key: 'TKSAD',
    title: 'Ticket - Duyệt giải pháp',
    queryFn: 'tickets',
    returnUrl: urlPath.solution.location
  },
  {
    key: 'TKSAA',
    title: 'Ticket - Duyệt giải pháp',
    queryFn: 'tickets',
    returnUrl: urlPath.solution.location
  },
  {
    key: 'TKNC',
    title: 'Ticket - Đồng ý kết quả xử lý',
    queryFn: 'tickets',
    returnUrl: urlPath.myTicket.location
  },
  {
    key: 'TKRC',
    title: 'Ticket - Không đồng ý kết quả xử lý',
    queryFn: 'tickets',
    returnUrl: urlPath.solution.location
  },
  {
    key: 'TICKET_OWNER_OT',
    title: 'Tăng ca - Xác nhận tăng ca',
    queryFn: 'tickets',
    returnUrl: urlPath.myTicket.location
  },
  {
    key: 'TICKET_OWNER_BUS',
    title: 'Ticket - Xác nhận công tác',
    queryFn: 'tickets',
    returnUrl: urlPath.myTicket.location
  },
  //#endregion TICKET

  //#region TOPIC
  {
    key: 'TOPIC_TAG',
    title: 'Ban được nhắc đến trong tin nhắn',
    queryFn: 'topics',
    returnUrl: urlPath.topic.location
  },
  {
    key: 'TOPIC_MESSAGE',
    title: 'Tin nhắn mới',
    queryFn: 'topics',
    returnUrl: urlPath.topic.location
  },
  //#endregion

  //#region LEAVE
  {
    key: 'LEAVE_APPROVAL',
    title: 'Nghỉ phép - Phê duyệt',
    queryFn: 'leaveTickets',
    returnUrl: urlPath.leaveTicketApproval.location
  },
  {
    key: 'LEAVE_APPROVED',
    title: 'Nghỉ phép - Phê duyệt',
    queryFn: 'leaveTickets',
    returnUrl: urlPath.myLeaveTicket.location
  },
  {
    key: 'LEAVE_REJECTED',
    title: 'Nghỉ phép - Từ chối',
    queryFn: 'leaveTickets',
    returnUrl: urlPath.myLeaveTicket.location
  },
  {
    key: 'LEAVE_CANCELLED_APPROVAL',
    title: 'Nghỉ phép - Thu hồi duyệt',
    queryFn: 'leaveTickets',
    returnUrl: urlPath.leaveTicketApproval.location
  },
  {
    key: 'LEAVE_CANCELLED',
    title: 'Nghỉ phép - Hủy phiếu',
    queryFn: 'leaveTickets',
    returnUrl: urlPath.leaveTicketApproval.location
  },
  //#endregion

  //#region LEAVE PACKAGE
  {
    key: 'LEAVE_PACKAGE_APPROVAL',
    title: 'Nghỉ phép - Phê duyệt',
    queryFn: 'leaveTicketPackages',
    returnUrl: urlPath.leaveTicketPackageApproval.location
  },
  {
    key: 'LEAVE_PACKAGE_CANCELLED_APPROVAL',
    title: 'Nghỉ phép - Thu hồi duyệt',
    queryFn: 'leaveTicketPackages',
    returnUrl: urlPath.leaveTicketPackageApproval.location
  },
  {
    key: 'LEAVE_PACKAGE_CANCELLED',
    title: 'Nghỉ phép - Hủy phiếu',
    queryFn: 'leaveTicketPackages',
    returnUrl: urlPath.leaveTicketPackageApproval.location
  },
  //#endregion

  //#region OT
  {
    key: 'OT_APPROVAL',
    title: 'Tăng ca - Phê duyệt',
    queryFn: 'overtimeTickets',
    returnUrl: urlPath.overtimeTicketApproval.location
  },
  {
    key: 'OT_APPROVED',
    title: 'Tăng ca - Phê duyệt',
    queryFn: 'overtimeTickets',
    returnUrl: urlPath.myOvertimeTicket.location
  },
  {
    key: 'OT_SUPERVISOR_CONFIRM',
    title: 'Tăng ca - Giám sát xác nhận',
    queryFn: 'overtimeTickets',
    returnUrl: urlPath.overtimeTicketSupervisorConfirm.location
  },
  {
    key: 'OT_REJECTED',
    title: 'Tăng ca - Từ chối',
    queryFn: 'overtimeTickets',
    returnUrl: urlPath.myOvertimeTicket.location
  },
  {
    key: 'OT_CANCELLED_APPROVAL',
    title: 'Tăng ca - Thu hồi duyệt',
    queryFn: 'overtimeTickets',
    returnUrl: urlPath.overtimeTicketApproval.location
  },
  //#endregion

  //#region BUSINESS TRIP
  {
    key: 'TRIP_APPROVAL',
    title: 'Công tác - Phê duyệt',
    queryFn: 'businessTripTickets',
    returnUrl: urlPath.businessTripTicketApproval.location
  },
  {
    key: 'TRIP_APPROVED',
    title: 'Công tác - Phê duyệt',
    queryFn: 'businessTripTickets',
    returnUrl: urlPath.myBusinessTripTicket.location
  },
  {
    key: 'TRIP_REJECTED',
    title: 'Công tác - Từ chối',
    queryFn: 'businessTripTickets',
    returnUrl: urlPath.myBusinessTripTicket.location
  },
  {
    key: 'TRIP_CANCELLED_APPROVAL',
    title: 'Công tác - Thu hồi duyệt',
    queryFn: 'businessTripTickets',
    returnUrl: urlPath.businessTripTicketApproval.location
  },
  //#endregion

  //#region TRANSPORTATION TICKET
  {
    key: 'TRAN_APPROVAL', // Gửi thông báo cho người phê duyệt
    title: 'Vận chuyển - Phê duyệt',
    queryFn: 'transportationTicket',
    returnUrl: urlPath.transportationTicketApproval.location
  },
  {
    key: 'TRAN_APPROVED', // Gửi thông báo đã phê duyệt cho người tạo phiếu
    title: 'Vận chuyển - Phê duyệt',
    queryFn: 'transportationTicket',
    returnUrl: urlPath.myTransportationTicket.location
  },
  {
    key: 'TRAN_REJECTED',
    title: 'Vận chuyển - Từ chối',
    queryFn: 'transportationTicket',
    returnUrl: urlPath.myTransportationTicket.location
  },
  {
    key: 'TRAN_CANCELLED_APPROVAL', // Gửi thông báo đã xác nhận cho người tạo phiếu
    title: 'Vận chuyển - Thu hồi duyệt',
    queryFn: 'transportationTicket',
    returnUrl: urlPath.transportationTicketApproval.location
  },
  {
    key: 'TRAN_CONFIRM', // Gửi thông báo cho người xác nhận
    title: 'Vận chuyển - Xác nhận',
    queryFn: 'transportationTicket',
    returnUrl:
      userInfo?.departmentId === DepartmentEn.hr
        ? urlPath.hrConfirmTransportationTicket.location
        : urlPath.transportationTicketConfirm.location
  },
  {
    key: 'TRAN_CONFIRMED', // Gửi thông báo đã xác nhận cho người tạo phiếu
    title: 'Vận chuyển - Xác nhận',
    queryFn: 'transportationTicket',
    returnUrl: urlPath.myTransportationTicket.location
  },
  {
    key: 'TRAN_REJECT_CONFIRM', // Gửi thông báo đã từ chối xác nhận cho người tạo phiếu
    title: 'Vận chuyển - Xác nhận',
    queryFn: 'transportationTicket',
    returnUrl: urlPath.myTransportationTicket.location
  },
  {
    key: 'DRIVER_CONFIRMED', // Gửi thông báo cho tài xế
    title: 'Vận chuyển - Tài xế xử lý',
    queryFn: 'transportationTicket',
    returnUrl: urlPath.transportationTicketHandle.location
  },
  //#endregion

  //#region PURCHASE REQUEST TICKET
  {
    key: 'PR_APPROVAL',
    title: 'Mua hàng - Phê duyệt',
    queryFn: 'purchaseRequestTickets',
    returnUrl: urlPath.purchaseRequestTicketApproval.location
  },
  {
    key: 'PR_APPROVED',
    title: 'Mua hàng - Phê duyệt',
    queryFn: 'purchaseRequestTickets',
    returnUrl: urlPath.myPurchaseRequestTicket.location
  },
  {
    key: 'PR_REJECTED',
    title: 'Mua hàng - Từ chối',
    queryFn: 'purchaseRequestTickets',
    returnUrl: urlPath.myPurchaseRequestTicket.location
  },
  {
    key: 'PR_CANCELLED_APPROVAL',
    title: 'Mua hàng - Thu hồi duyệt',
    queryFn: 'purchaseRequestTickets',
    returnUrl: urlPath.purchaseRequestTicketApproval.location
  },
  //#endregion

  //#region ADVANCE PAYMENT TICKET
  {
    key: 'AP_APPROVAL',
    title: 'Ứng tiền - Phê duyệt',
    queryFn: 'advancePaymentTickets',
    returnUrl: urlPath.advancePaymentTicketApproval.location
  },
  {
    key: 'AP_APPROVED',
    title: 'Ứng tiền - Phê duyệt',
    queryFn: 'advancePaymentTickets',
    returnUrl: urlPath.myAdvancePaymentTicket.location
  },
  {
    key: 'AP_REJECTED',
    title: 'Ứng tiền - Từ chối',
    queryFn: 'advancePaymentTickets',
    returnUrl: urlPath.myAdvancePaymentTicket.location
  },
  {
    key: 'AP_CANCELLED_APPROVAL',
    title: 'Ứng tiền - Thu hồi duyệt',
    queryFn: 'advancePaymentTickets',
    returnUrl: urlPath.advancePaymentTicketApproval.location
  },
  //#endregion

  //#region HAND OVER TICKET
  {
    key: 'HANDOVER_CONFIRM',
    title: 'Bàn giao - Phê duyệt',
    queryFn: 'handOverTickets',
    returnUrl: urlPath.myHandoverTicket.location
  },
  {
    key: 'HANDOVER_FINISHED',
    title: 'Ứng tiền - Xác nhận',
    queryFn: 'handOverTickets',
    returnUrl: urlPath.myHandoverTicket.location
  },
  {
    key: 'HANDOVER_CANCELED',
    title: 'Ứng tiền - Hủy xác nhận',
    queryFn: 'handOverTickets',
    returnUrl: urlPath.handoverTicketApproval.location
  },
  //#endregion

  //#region EQUIPMENT SUPPLY REQUEST TICKET
  {
    key: 'ESR_APPROVAL',
    title: 'Cấp thiết bị - Phê duyệt',
    queryFn: 'equipmentSupplyRequestTickets',
    returnUrl: urlPath.equipmentSupplyRequestTicketApproval.location
  },
  {
    key: 'ESR_APPROVED',
    title: 'Cấp thiết bị - Phê duyệt',
    queryFn: 'equipmentSupplyRequestTickets',
    returnUrl: urlPath.myEquipmentSupplyRequestTicket.location
  },
  {
    key: 'ESR_REJECTED',
    title: 'Cấp thiết bị - Từ chối',
    queryFn: 'equipmentSupplyRequestTickets',
    returnUrl: urlPath.myEquipmentSupplyRequestTicket.location
  },
  {
    key: 'ESR_CANCELLED_APPROVAL',
    title: 'Cấp thiết bị - Thu hồi duyệt',
    queryFn: 'equipmentSupplyRequestTickets',
    returnUrl: urlPath.equipmentSupplyRequestTicketApproval.location
  },
  {
    key: 'ESR_CANCELLED',
    title: 'Cấp thiết bị - Hủy phiếu',
    queryFn: 'equipmentSupplyRequestTickets',
    returnUrl: urlPath.equipmentSupplyRequestTicketApproval.location
  },
  //#endregion

  //#region WORK PLAN
  {
    key: 'PERTASK_APPROVAL',
    title: 'Kế hoạch cá nhân - Phê duyệt',
    queryFn: ['workPlansApproval', 'workPlan'],
    returnUrl: `${urlPath.workPlanDetail?.location}`
  },
  {
    key: 'PERTASK_APPROVED',
    title: 'Kế hoạch cá nhân - Phê duyệt',
    queryFn: ['workPlansApproval', 'workPlan'],
    returnUrl: `${urlPath.workPlanDetail?.location}`
  },
  {
    key: 'PERTASK_REJECTED',
    title: 'Kế hoạch cá nhân - Từ chối',
    queryFn: ['workPlansApproval', 'workPlan'],
    returnUrl: `${urlPath.workPlanDetail?.location}`
  },
  {
    key: 'PERTASK_CANCELLED_APPROVAL',
    title: 'Kế hoạch cá nhân - Thu hồi duyệt',
    queryFn: ['workPlansApproval', 'workPlan'],
    returnUrl: `${urlPath.workPlanDetail?.location}`
  }
  //#endregion
];
