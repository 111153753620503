import { Button, Flex, Result, Skeleton } from 'antd';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from 'src/contexts/app.context';

export default function Forbidden() {
  const navigate = useNavigate();
  const { resourcePermissionLoading, resources } = useContext(AppContext);
  return !!resourcePermissionLoading && !!resources ? (
    <Flex gap={10} vertical>
      <Skeleton.Input active />
      <Skeleton paragraph={{ rows: 5 }} active />
    </Flex>
  ) : (
    <Result
      status='403'
      title='403'
      subTitle='Sorry, you are not authorized to access this page.'
      extra={
        <Button type='primary' onClick={() => navigate(-1)}>
          Go Back
        </Button>
      }
    />
  );
}
