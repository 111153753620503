import type { FlatItem } from 'src/types/elements.type';
import type {
  IProductionUnit,
  IProductionUnitDetail,
  ProductionUnitAdd
} from 'src/types/master_data/productionUnit.type';
import type { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/production-units';

export async function getProductionUnits(
  searchCondition: string | undefined,
  sequenceStatus: string,
  page: number,
  pageSize: number,
  orderBy?: string
) {
  return await http.get<PagedList<IProductionUnit>>(`${controller}`, {
    params: {
      name: searchCondition,
      sequenceStatus,
      page,
      pageSize,
      orderBy
    }
  });
}

export async function getProductionUnit(id: number) {
  return await http.get<IProductionUnit>(`${controller}/${id}`);
}

export async function getProductionUnitDetails(code: string) {
  return await http.get<IProductionUnitDetail[]>(`${controller}/code/${code}`);
}

export async function deleteProductionUnit(id: number) {
  return await http.delete(`${controller}/${id}`);
}

export async function getProductionUnitsDropdown() {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
}

export async function addProductionUnit(productionUnit: ProductionUnitAdd) {
  return await http.post(controller, productionUnit);
}

export async function updateProductionUnit(id: number, productionUnit: ProductionUnitAdd) {
  return await http.put(`${controller}/${id}`, productionUnit);
}
