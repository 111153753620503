import { useMutation } from '@tanstack/react-query';
import { Button, Drawer, Select } from 'antd';
import Title from 'antd/es/typography/Title';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addWebConfig } from 'src/apis/helpdesk/webConfig.api';
import { WebConfigEn } from 'src/constants/webConfig.enum';
import { AppContext } from 'src/contexts/app.context';
import i18n from 'src/translation/i18n';
import { Language, Theme, WebConfigAdd } from 'src/types/webConfig.type';
import { showNotification } from 'src/utils/notification';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Setting = ({ open, setOpen }: Props) => {
  const [theme, setTheme] = useState<Theme>(localStorage.getItem(WebConfigEn.theme) as Theme);
  const [language, setLanguage] = useState<Language>(localStorage.getItem(WebConfigEn.language) as Language);

  const {
    theme: themeFromContext,
    setTheme: setThemeFromContext,
    language: languageFromContext,
    setLanguage: setLanguageFromContext
  } = useContext(AppContext);

  useEffect(() => {
    if (themeFromContext || languageFromContext) {
      setTheme(themeFromContext as Theme);
      localStorage.setItem(WebConfigEn.theme, String(themeFromContext));
      setLanguage(languageFromContext as Language);
      localStorage.setItem(WebConfigEn.language, String(languageFromContext));
    }
  }, [themeFromContext, languageFromContext]);

  const { t } = useTranslation();

  const addWebConfigMutation = useMutation({
    mutationFn: (webConfigs: WebConfigAdd[]) => addWebConfig(webConfigs)
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    addWebConfigMutation.mutate(
      [
        {
          key: WebConfigEn.language,
          value: language as Language
        },
        {
          key: WebConfigEn.theme,
          value: String(theme)
        }
      ],
      {
        onSuccess: (data) => {
          setThemeFromContext(theme);
          setLanguageFromContext(language);
          showNotification(t('notification.success.setting'), 'success');
        }
      }
    );
  };

  const handleCancel = () => {
    setTheme(localStorage.getItem(WebConfigEn.theme) as Theme);
    setThemeFromContext(localStorage.getItem(WebConfigEn.theme) as Theme);
    setLanguage(localStorage.getItem(WebConfigEn.language) as Language);
    i18n.language !== localStorage.getItem(WebConfigEn.language) &&
      i18n.changeLanguage(localStorage.getItem(WebConfigEn.language) as Language);
  };

  return (
    <Drawer
      placement='right'
      onClose={handleClose}
      open={open}
      maskClosable={true}
      closable={false}
      width={300}
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            columnGap: '10px'
          }}
        >
          <Button onClick={() => handleCancel()}>{t('action.cancel')}</Button>
          <Button
            type='primary'
            onClick={() => {
              handleSubmit();
            }}
            loading={addWebConfigMutation.isLoading}
          >
            {t('action.save')}
          </Button>
        </div>
      }
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 15
        }}
      >
        <Title
          level={5}
          style={{
            marginBottom: 0,
            cursor: 'pointer'
          }}
        >
          {t('value.theme')}
        </Title>
        <Select
          style={{
            width: 110
          }}
          value={theme}
          onChange={(value) => {
            setTheme(value);
            setThemeFromContext(value);
          }}
          options={[
            {
              label: t('value.light'),
              value: WebConfigEn.light
            },
            {
              label: t('value.dark'),
              value: WebConfigEn.dark
            }
          ]}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Title
          level={5}
          style={{
            marginBottom: 0
          }}
        >
          {t('value.language')}
        </Title>
        <Select
          style={{
            width: 110
          }}
          onChange={(value) => {
            setLanguage(value);
            i18n.language !== value && i18n.changeLanguage(value);
          }}
          value={language}
          options={[
            {
              label: t('value.vi'),
              value: WebConfigEn.vietnamese
            },
            {
              label: t('value.en'),
              value: WebConfigEn.english
            }
          ]}
        />
      </div>
    </Drawer>
  );
};

export default Setting;
