import type { FlatItem } from 'src/types/elements.type';
import type { IProcess, ProcessAdd } from 'src/types/item_master_data/process.type';
import type { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/processes';

export const getProcesses = async (page: number, pageSize: number, orderBy?: string) => {
  return await http.get<PagedList<IProcess>>(`${controller}`, {
    params: {
      page,
      pageSize,
      orderBy
    }
  });
};

export const getProcess = async (id: number) => {
  return http.get<IProcess>(`${controller}/${id}`);
};

export const addProcess = async (process: ProcessAdd) => {
  return http.post<ProcessAdd>(controller, process);
};

export const addProcessMultiple = async (formData: { jobCategoryId: number }) => {
  return http.post<ProcessAdd>(`${controller}/auto-add`, formData);
};

export const updateProcess = async (id: number, process: IProcess) => {
  return await http.put<IProcess[]>(`${controller}/${id}`, process);
};

export const deleteProcess = async (id: number) => {
  await http.delete(`${controller}/${id}`);
};

export const getProcessesDropdown = async () => {
  return http.get<FlatItem[]>(`${controller}/dropdown`);
};
