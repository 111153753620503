import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getTransportationTypesDropdown } from 'src/apis/hr_ticket/transportationTicket.api';
import { SelectProps } from 'src/types/elements.type';
import { SizeType } from 'antd/es/config-provider/SizeContext';

type Props = SelectProps & {
  size?: SizeType;
  disabled?: boolean;
};

export default function TransportationTypeSelect({
  value,
  style,
  disabled,
  allowClear = true,
  onChange,
  placeholder,
  size = 'middle'
}: Props) {
  const transportationTypesDropdownQuery = useQuery({
    queryKey: ['transportationTypesDropdown'],
    queryFn: () => getTransportationTypesDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={transportationTypesDropdownQuery.isFetching ? undefined : value}
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      options={transportationTypesDropdownQuery.data?.data.map((data) => ({
        value: data.id,
        label: data.name
      }))}
      disabled={disabled}
      size={size}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={transportationTypesDropdownQuery.isFetching}
    />
  );
}
