import { Button, Col, Drawer, Flex, Row, Typography } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ResourceThumbnail from 'src/components/v2/Thumbnail/ResourceThumbnail';
import type { ResourceShort } from 'src/types/master_data/resource.type';
// @ts-ignore
import { CaretUpOutlined, CloseOutlined, HomeOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import Title from 'antd/es/typography/Title';
import { getResourcesByParent } from 'src/apis/master_data/resource.api';
import urlPath from 'src/constants/urlPath/urlPath';
import { AppContext } from 'src/contexts/app.context';
import { buildHierarchy } from 'src/utils/collection';
import './ApplicationMenu.scss';

const { Text } = Typography;
type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type MenuItem = {
  label: React.ReactNode;
  key: React.Key;
  type?: string;
  icon?: React.ReactNode;
  children?: MenuItem[];
};

// interface LevelKeysProps {
//   key?: string;
//   children?: LevelKeysProps[];
// }

// const getLevelKeys = (items1: LevelKeysProps[]) => {
//   const key: Record<string, number> = {};
//   const func = (items2: LevelKeysProps[], level = 1) => {
//     items2.forEach((item) => {
//       if (item.key) {
//         key[item.key] = level;
//       }
//       if (item.children) {
//         func(item.children, level + 1);
//       }
//     });
//   };
//   func(items1);
//   return key;
// };

export default function ApplicationMenu({ open, setOpen }: Props) {
  const navigate = useNavigate();
  // const location = useLocation();
  // const [stateOpenKeys, setStateOpenKeys] = useState<string[]>([]);
  const {
    rootResource,
    setOpenAppMenu,
    rootResources,
    setRootResource,
    currentResource,
    setCurrentResource,
    setOpenTicketManagement
  } = useContext(AppContext);
  const [tempRootResource, setTempRootResource] = useState<ResourceShort | undefined>(undefined);

  const rootResourceQuery = useQuery({
    queryKey: ['rootResourceByPath'],
    queryFn: async () => await getResourcesByParent(undefined, undefined, true),
    staleTime: 60 * 1000,
    enabled: !rootResource?.children
  });

  useEffect(() => {
    if (rootResourceQuery.data) {
      const hierarchyMenu = buildHierarchy(rootResourceQuery.data?.data, rootResourceQuery.data?.data?.[0]?.parentId);

      const newRootResource = hierarchyMenu?.find((x) => x?.id === currentResource?.parentId);

      setRootResource(newRootResource);
    }
  }, [rootResourceQuery.data, setRootResource, currentResource?.parentId]);

  useEffect(() => {
    if (rootResource) {
      setTempRootResource(rootResource);
    }
  }, [rootResource]);

  const convertTreeToMenuItems = useCallback((resource: ResourceShort): MenuItem[] => {
    let menuItems: MenuItem[] = [];

    if (resource.children) {
      for (const childItem of resource?.children?.sort((a, b) => a.sort - b.sort)) {
        if (childItem.children && childItem.children.length > 0) {
          const menuItem: MenuItem = {
            key: JSON.stringify({ id: childItem.id, path: childItem.path }),
            label: <Text strong>{childItem.name}</Text>,
            icon: childItem?.iconPath ? <ResourceThumbnail filePath={childItem?.iconPath} size={24} /> : undefined,
            children: convertTreeToMenuItems(childItem)
          };
          menuItems.push(menuItem);
        } else {
          const menuItem: MenuItem = {
            key: JSON.stringify({ id: childItem.id, path: childItem.path }),
            label: childItem.path ? (
              childItem.name
            ) : (
              <Flex wrap='nowrap' gap={10}>
                <Text>{childItem.name}</Text>
                <Text type='secondary'>(Not Available)</Text>
              </Flex>
            ),
            icon: childItem?.iconPath ? <ResourceThumbnail filePath={childItem?.iconPath} size={24} /> : undefined
          };
          menuItems.push(menuItem);
        }
      }
    }

    return menuItems;
  }, []);

  // const onOpenChange: MenuProps['onOpenChange'] = (openKeys) => {
  //   const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
  //   // open
  //   if (currentOpenKey !== undefined) {
  //     const repeatIndex = openKeys
  //       .filter((key) => key !== currentOpenKey)
  //       .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

  //     setStateOpenKeys(
  //       openKeys
  //         // remove repeat key
  //         .filter((_, index) => index !== repeatIndex)
  //         // remove current level all child
  //         .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
  //     );
  //   } else {
  //     // close
  //     setStateOpenKeys(openKeys);
  //   }
  // };

  // useEffect(() => {
  //   if (open.resource) {
  //     const menus = convertTreeToMenuItems(open.resource);
  //     setMenuItems(menus);
  //   }
  // }, [convertTreeToMenuItems, open.resource]);

  return (
    <Drawer
      className={'app-menu-drawer'}
      placement='right'
      width={'100vw'}
      size='default'
      zIndex={10000}
      open={open}
      closable={false}
    >
      <img
        // src='https://images.unsplash.com/photo-1686924878014-06bc9aa4a86d?q=80&w=1933&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        src='https://images.unsplash.com/photo-1469474968028-56623f02e42e?q=80&w=2074&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        alt='background'
      />
      <div className='app-menu-container'>
        <div className='app-menu'>
          <Flex vertical gap={20}>
            <Row className='root-app-menu'>
              {rootResources?.map((x, i) => (
                <Col flex='150px' key={i}>
                  <Flex
                    className='app-item'
                    vertical
                    justify='space-evenly'
                    align='center'
                    gap={10}
                    onClick={() => {
                      if (x?.path === urlPath.ticketRaising.location) {
                        setOpenAppMenu(false);
                        setOpenTicketManagement(true);
                      } else {
                        setTempRootResource(x);
                      }
                    }}
                  >
                    <ResourceThumbnail filePath={x?.iconPath} size={50} />
                    <Text className='app-item-text' style={{ textAlign: 'center' }}>
                      {x?.name}
                    </Text>
                    {x?.id === tempRootResource?.id && (
                      <div className='current-arrow'>
                        <CaretUpOutlined />
                      </div>
                    )}
                  </Flex>
                </Col>
              ))}
            </Row>
            <Title className='app-menu-title' level={1}>
              {(tempRootResource || rootResource)?.name || ''}
            </Title>
            <Row className='children-app'>
              {(tempRootResource || rootResource)?.children?.map((x) => (
                <Col flex='150px' key={x.id}>
                  <Flex
                    className='app-item'
                    vertical
                    justify='space-evenly'
                    align='center'
                    gap={10}
                    onClick={() => {
                      setOpenAppMenu(false);
                      tempRootResource && setRootResource(tempRootResource);
                      setCurrentResource(x);
                      x?.path && navigate(x.path);
                    }}
                  >
                    <ResourceThumbnail filePath={x?.iconPath} size={50} />
                    <Text className='app-item-text' style={{ textAlign: 'center' }}>
                      {x?.name}
                    </Text>
                  </Flex>
                </Col>
              ))}
            </Row>

            <Flex gap={20} align='end' justify='center'>
              <Button
                style={{ width: '200px' }}
                size='large'
                icon={<HomeOutlined />}
                onClick={() => {
                  navigate('/');
                  setOpen(false);
                }}
              >
                Về trang chủ
              </Button>
              <Button style={{ width: '200px' }} size='large' icon={<CloseOutlined />} onClick={() => setOpen(false)}>
                Đóng
              </Button>
            </Flex>
          </Flex>
        </div>
      </div>
    </Drawer>
  );
}
