import {
  BellOutlined,
  KeyOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Avatar, Badge, Button, Dropdown, Layout, List, MenuProps, Popover, Space, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  getNotifications,
  markAllNotificationsAsRead,
  markNotificationsAsRead
} from 'src/apis/helpdesk/notification.api';
import urlPath from 'src/constants/urlPath/urlPath';
import { AppContext } from 'src/contexts/app.context';
import useScreenSize from 'src/hooks/useScreenSize';
import ChangePassword from 'src/pages/Config/ChangePassword';
import Config from 'src/pages/Config/Setting';
import { UserInfo } from 'src/types/master_data/employee.type';
import { INotification } from 'src/types/notification.type';
import { getAccessTokenFromLS } from 'src/utils/auth';
import { showNotification } from 'src/utils/notification';
import { handleInvalidate, handleNavigate } from 'src/utils/utils';
const { Header: HeaderAntd } = Layout;
const { Text } = Typography;

export interface NotificationType {
  id: string;
  title: string;
  type: string;
  createdDate: string;
  isRead: boolean;
}

interface Props {
  colorBgContainer: string;
}

export default function Header({ colorBgContainer }: Props) {
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [notificationItems, setNotificationItems] = useState<NotificationType[]>([]);
  const [connection, setConnection] = useState<HubConnection | undefined>(undefined);
  const [userInfo, setUserInfo] = useState<UserInfo | null>();
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);
  const [openConfig, setOpenConfig] = useState<boolean>(false);

  const { userInfo: userInfoFromContext } = useContext(AppContext);

  const { theme } = useContext(AppContext);

  const isSmallScreen = useScreenSize() <= 1024;

  useEffect(() => {
    setUserInfo(userInfoFromContext);
  }, [userInfoFromContext]);

  const { isSidebarCollapsed, setIsSidebarCollapsed } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const listNotification = () => (
    <Spin spinning={markAllNotificationsAsReadMutation.isLoading}>
      <List
        itemLayout='horizontal'
        dataSource={notificationItems}
        footer={
          notificationItems?.filter((item) => !item.isRead).length > 0 && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button type='link' onClick={() => handleMarkAllRead()}>
                {t('action.markAllAsRead')}
              </Button>
            </div>
          )
        }
        renderItem={(item, index) => (
          <List.Item style={{ backgroundColor: item.isRead || theme === 'dark' ? '' : '#f6f8fa' }}>
            <List.Item.Meta
              title={
                <span
                  onClick={(e) => {
                    handleNavigate(item, markNotificationsAsReadMutation, navigate);
                  }}
                  style={{ cursor: 'pointer', fontStyle: 'unset' }}
                >
                  {item.title}
                </span>
              }
              style={{
                padding: '0px 10px'
              }}
              description={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>{item.createdDate.toString()}</div>
                  {!item.isRead && (
                    <div>
                      <Button type='link' onClick={() => handleMarkRead(item.id)}>
                        {t('action.markAsRead')}
                      </Button>
                    </div>
                  )}
                </div>
              }
            />
          </List.Item>
        )}
      />
    </Spin>
  );

  useQuery({
    queryKey: ['notifications'],
    queryFn: () => getNotifications(),
    onSuccess: (data) => {
      const sortedNotifications = data.data.details
        ?.filter((item) => dayjs(item.createdDate).isValid())
        .sort((a, b) => dayjs(b.createdDate).valueOf() - dayjs(a.createdDate).valueOf());
      setNotificationItems(
        sortedNotifications?.map((item) => ({
          id: item.id,
          title: item.message,
          type: item.type,
          createdDate: dayjs(item.createdDate).format('DD/MM/YYYY HH:mm'),
          isRead: false
        }))
      );
      setNotificationCount(data.data.count || 0);
    },
    staleTime: 60 * 1000
  });

  const markNotificationsAsReadMutation = useMutation({
    mutationFn: (id?: string) => markNotificationsAsRead(id),
    onSuccess: (_, id) => {
      const notifications = notificationItems?.map((item) => {
        if (item.id === id || !id) {
          item.isRead = true;
        }
        return item;
      });
      setNotificationItems(notifications);
      setNotificationCount(id ? notificationCount - 1 : 0);
    }
  });

  const markAllNotificationsAsReadMutation = useMutation({
    mutationFn: () => markAllNotificationsAsRead(),
    onSuccess: () => {
      const notifications = notificationItems?.map((item) => {
        item.isRead = true;
        return item;
      });
      setNotificationItems(notifications);
      setNotificationCount(0);
    }
  });

  const handleMarkRead = (id: string) => {
    markNotificationsAsReadMutation.mutate(id);
  };

  const handleMarkAllRead = () => {
    markAllNotificationsAsReadMutation.mutate();
  };

  const handleChangePassword = () => {
    setOpenChangePassword(true);
  };

  const handleOpenConfig = () => {
    setOpenConfig(true);
  };

  const handleLogout = async () => {
    const returnUrl = window.location.pathname;
    localStorage.setItem('returnUrl', returnUrl);
    navigate(urlPath.signoutOidc.location);
  };

  useEffect(() => {
    const notificationUrl =
      process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_NOTIFICATION_ENDPOINT_PROD
        : process.env.REACT_APP_NOTIFICATION_ENDPOINT;

    const accessToken = getAccessTokenFromLS();
    if (accessToken) {
      const connect = new HubConnectionBuilder()
        .withUrl(String(notificationUrl), {
          accessTokenFactory: () => accessToken
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.None)
        .build();

      setConnection(connect);
    }
  }, []);

  useEffect(() => {
    if (connection) {
      if (connection.state === 'Disconnected') {
        connection.start().then(() => {
          connection.on('ReceiveNotifications', (message: INotification) => {
            setNotificationItems((prev) => {
              const newNotification = {
                id: message.id,
                title: message.message,
                type: message.type,
                createdDate: dayjs(message.createdDate).format('DD/MM/YYYY HH:mm'),
                isRead: false
              };
              if (prev?.length >= 5) {
                prev.pop();
              }
              return [newNotification, ...(prev || [])];
            });
            setNotificationCount((prev) => prev + 1);
            showNotification(t('notification.info.newMessage'), 'info');
            handleInvalidate(message.type, queryClient);
          });
        });
      }
    }
  }, [connection, t, queryClient, navigate]);

  const items: MenuProps['items'] = [
    {
      key: '1',
      style: { cursor: 'default' },
      label: (
        <Button type='link' style={{ cursor: 'default' }} title={`${userInfo?.code} - ${userInfo?.fullName}`}>
          Hi, {userInfo?.fullName}
        </Button>
      )
    },
    {
      key: '2',
      onClick: () => {
        handleOpenConfig();
      },
      label: (
        <Button type='link'>
          <SettingOutlined />
          <span style={{ marginLeft: 10 }}>{t('action.setting')}</span>
        </Button>
      )
    },
    {
      key: '3',
      onClick: () => {
        handleChangePassword();
      },
      label: (
        <Button type='link'>
          <KeyOutlined />
          <span style={{ marginLeft: 10 }}>{t('action.changePassword')}</span>
        </Button>
      )
    },
    {
      key: '4',
      onClick: () => {
        handleLogout();
      },
      label: (
        <Button type='link'>
          <LogoutOutlined />
          <span style={{ marginLeft: 10 }}>{t('action.logout')}</span>
        </Button>
      )
    }
  ];

  return (
    <HeaderAntd
      style={{
        boxShadow: 'none',
        paddingLeft: 0,
        background: colorBgContainer,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: 16
      }}
    >
      <div style={{ flex: 1 }}>
        <Button
          type='text'
          icon={isSidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
          style={{
            fontSize: '16px',
            width: 64,
            height: 64,
            display: isSmallScreen ? 'none' : 'block'
          }}
        />
      </div>
      <Space size={'middle'}>
        <Popover
          placement='bottomLeft'
          content={listNotification}
          trigger='click'
          overlayStyle={{
            width: 336
          }}
        >
          <Badge count={notificationCount} overflowCount={10}>
            <Avatar icon={<BellOutlined />} style={{ cursor: 'pointer', backgroundColor: '#036EB7' }} />
          </Badge>
        </Popover>
        <Dropdown menu={{ items }} placement='bottomRight' arrow>
          <Space align='center' style={{ cursor: 'pointer' }}>
            <Avatar style={{ backgroundColor: '#036EB7' }} icon={<UserOutlined />} />
            <Text strong style={{ display: 'flex', fontSize: 13, marginTop: 10 }}>
              {userInfo?.fullName}
            </Text>
          </Space>
        </Dropdown>
      </Space>
      <ChangePassword open={openChangePassword} setOpen={setOpenChangePassword} />
      <Config open={openConfig} setOpen={setOpenConfig} />
    </HeaderAntd>
  );
}
