import { useQuery } from '@tanstack/react-query';
import { Select, Typography } from 'antd';
import React from 'react';
import { getEquipmentSupplyRequestTicketsDropdown } from 'src/apis/hr_ticket/equipmentSupplyRequestTicket.api';

const { Text } = Typography;

interface Props {
  className?: string;
  value?: number[];
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  mode?: 'multiple' | 'tags';
  maxTagCount?: number | 'responsive';
  onChange?: (value: number[]) => void;
  disabled?: boolean;
}

export default function EquipmentSupplyRequestTicketSelect({
  className,
  value,
  style,
  allowClear,
  mode = 'tags',
  maxTagCount,
  onChange,
  disabled
}: Props) {
  const equipmentSupplyRequestTicketsDropdownQuery = useQuery({
    queryKey: ['equipmentSupplyRequestTicketsDropdown'],
    queryFn: () => getEquipmentSupplyRequestTicketsDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      className={className}
      allowClear={allowClear}
      value={equipmentSupplyRequestTicketsDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      mode={mode}
      disabled={disabled}
      maxTagCount={maxTagCount}
      options={equipmentSupplyRequestTicketsDropdownQuery.data?.data?.map((item) => ({
        value: item.id,
        label: (
          <Text>
            {item.code} - {item.name}
          </Text>
        )
      }))}
      showSearch
      loading={equipmentSupplyRequestTicketsDropdownQuery.isFetching}
    />
  );
}
