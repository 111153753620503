import type { FlatItem } from 'src/types/elements.type';
import { IPermission, PermissionAdd } from 'src/types/master_data/permission.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/permissions';

export async function getPermissions(sequenceStatus: string, page: number, pageSize: number, orderBy?: string) {
  return await http.get<PagedList<IPermission>>(`${controller}`, {
    params: {
      sequenceStatus,
      page,
      pageSize,
      orderBy
    }
  });
}

export async function getPermission(id: number) {
  return await http.get<IPermission>(`${controller}/${id}`);
}

export async function deletePermission(id: number) {
  return await http.delete(`${controller}/${id}`);
}

export async function getPermissionsDropdown() {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
}

export async function addPermission(Permission: PermissionAdd) {
  return await http.post<IPermission[]>(controller, Permission);
}

export async function updatePermission(id: number, Permission: IPermission) {
  return await http.put<IPermission[]>(`${controller}/${id}`, Permission);
}
