const authConfig = {
  authority: process.env.REACT_APP_AUTH_AUTHORITY,
  client_id:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_AUTH_CLIENT_ID_PROD
      : process.env.REACT_APP_AUTH_CLIENT_ID,
  redirect_uri:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_AUTH_REDIRECT_URI_PROD
      : process.env.REACT_APP_AUTH_REDIRECT_URI,
  response_type: process.env.REACT_APP_AUTH_RESPONSE_TYPE,
  scope: window.location.pathname.includes('/s/qr-scanner')
    ? process.env.REACT_APP_AUTH_SCOPE_SUPPORT
    : process.env.REACT_APP_AUTH_SCOPE,
  post_logout_redirect_uri:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI_PROD
      : process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI,
  automaticSilentRenew: true,
  loadUserInfo: true,
  monitorSession: false
};

export default authConfig;
