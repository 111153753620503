import { CategoryEnum } from 'src/constants/item_master_data/itemMasterData.enum';
import type { FlatItem } from 'src/types/elements.type';
import { IModel, ModelAdd, ModelImportAdd, type ModelShort } from 'src/types/item_master_data/model.type';
import { IModelMapping, ModelMappingAdd } from 'src/types/item_master_data/modelMapping.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'pm/models';

export const getModelsDropdown = async (categoryId: CategoryEnum) => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`, {
    params: {
      categoryId
    }
  });
};
export const getModelsByItemShort = async (itemId: number) => {
  return await http.get<ModelShort[]>(`${controller}/${itemId}/short`);
};

export const getModels = async (
  sequenceStatus: string,
  searchCondition: string | undefined,
  categoryId: CategoryEnum,
  page: number,
  pageSize: number,
  orderBy?: string,
  itemMasterDataId?: number
) => {
  return await http.get<PagedList<IModel>>(controller, {
    params: {
      sequenceStatus,
      searchCondition,
      categoryId,
      itemMasterDataId,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getModel = async (id: number) => {
  return await http.get<IModel>(`${controller}/${id}`);
};

export const exportTicketTypes = async (
  searchCondition: string | undefined,
  categoryId: CategoryEnum,
  itemMasterDataId?: number
) =>
  await http.get<IModel[]>(`${controller}/export`, {
    params: {
      searchCondition,
      categoryId,
      itemMasterDataId
    }
  });

export const addModel = async (model: ModelAdd) => {
  return await http.post(controller, model);
};

export const addModels = async (models: ModelImportAdd) => {
  return await http.post(`${controller}/add-list`, models);
};

export const updateModel = async (id: number, model: ModelAdd) => {
  return await http.put(`${controller}/${id}`, model);
};

export const deleteModel = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};

// Mapping
export const getModelMappings = async (categoryId?: CategoryEnum) => {
  return await http.get<IModelMapping[]>(`${controller}/mappings/get-all`, {
    params: {
      categoryId
    }
  });
};

export const getModelMapping = async (id: number) => {
  return await http.get<IModelMapping>(`${controller}/mappings/${id}`);
};

export const addModelMappings = async (modelMappings: ModelMappingAdd[]) => {
  return await http.post(`${controller}/mappings/add-list`, modelMappings);
};
