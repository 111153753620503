import { useQuery } from '@tanstack/react-query';
import { Empty, Select, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { getDepartmentChildDropdown } from 'src/apis/master_data/department.api';
import type { FlatItem } from 'src/types/elements.type';
const { Text } = Typography;

interface Props {
  value?: number;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  onChange?: (value: number) => void;
  disabled?: boolean;
  departmentParentId?: number | undefined;
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';
  placeholder?: string;
}

export default function DepartmentChildrenSelect({
  value,
  style,
  allowClear = true,
  onChange,
  disabled,
  departmentParentId,
  placement,
  placeholder
}: Props) {
  const [options, setOptions] = useState<FlatItem[]>([]);

  const departmentChildDropdownQuery = useQuery({
    queryKey: ['departmentChildDropdown'],
    queryFn: () => getDepartmentChildDropdown(departmentParentId),
    staleTime: 5 * 60 * 1000,
    enabled: !!departmentParentId
  });

  useEffect(() => {
    if (departmentChildDropdownQuery.data?.data) {
      const options = departmentChildDropdownQuery.data.data.filter(({ id }) => id !== departmentParentId);

      setOptions(departmentParentId ? options : []);
    }
  }, [departmentChildDropdownQuery.data, departmentParentId]);

  return (
    <Select
      allowClear={allowClear}
      value={departmentChildDropdownQuery.isFetching ? undefined : value}
      style={style}
      placement={placement}
      onChange={onChange}
      disabled={disabled}
      options={options?.map((option: FlatItem) => ({
        value: option.id,
        label: option.name
      }))}
      notFoundContent={
        <Empty
          description={<Text type='secondary'>Vui lòng chọn bộ phận</Text>}
          image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
          imageStyle={{ height: 60 }}
        />
      }
      placeholder={placeholder}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={departmentChildDropdownQuery.isFetching}
    />
  );
}
