import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getLeaveTypes } from 'src/apis/hr_ticket/leaveType.api';
import { SelectProps } from 'src/types/elements.type';

export default function LeaveTypeSelect({ value, style, allowClear = true, onChange, placeholder }: SelectProps) {
  const leaveTypeQuery = useQuery({
    queryKey: ['leaveType'],
    queryFn: () => getLeaveTypes(),
    staleTime: 60 * 1000 * 60
  });

  const newValue = leaveTypeQuery.data?.data.find((item) => String(item.id) === value);

  return (
    <Select
      allowClear={allowClear}
      value={leaveTypeQuery.isFetching ? undefined : JSON.stringify(newValue)}
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      options={leaveTypeQuery.data?.data.map((data) => ({
        value: JSON.stringify(data),
        label: data.name
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={leaveTypeQuery.isFetching}
    />
  );
}
