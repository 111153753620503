import { IPassword } from 'src/types/password.type';
import { http } from 'src/utils/http';

const URL_CHANGE_PASSWORD = 'api/Users/ChangePassWordComparePass';

export const changePassword = async (password: IPassword) => {
  const is4ApiUrl = process.env.REACT_APP_AUTH_AUTHORITY_API;
  http.defaults.baseURL = is4ApiUrl;
  return await http.post(URL_CHANGE_PASSWORD, password);
};
