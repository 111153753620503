import { AssetAdd, IAsset } from 'src/types/master_data/asset.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/assets';

export const getAssets = async (
  searchCondition: string | undefined,
  sequenceStatus: string,
  page: number,
  pageSize: number,
  orderBy?: string
) => {
  return await http.get<PagedList<IAsset>>(`${controller}`, {
    params: {
      name: searchCondition,
      sequenceStatus,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getAsset = async (id: number) => {
  return http.get<IAsset>(`${controller}/${id}`);
};

export const addAsset = async (asset: AssetAdd) => {
  return http.post<IAsset>(controller, asset);
};

export const updateAsset = async (id: number, asset: IAsset) => {
  return await http.put<IAsset[]>(`${controller}/${id}`, asset);
};

export const deleteAsset = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};

export const getAssetsDropdown = async () => {
  return await http.get<IAsset[]>(`${controller}/dropdown`);
};
