import {
  IUserResource,
  IUserResourceFullAccess,
  UserResourceAdd,
  UserResourceCopyAdd
} from 'src/types/master_data/userResource.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/user-resources';

export const getUserResources = async (
  userId: string | undefined,
  page: number,
  pageSize: number,
  orderBy?: string
) => {
  return await http.get<PagedList<IUserResource>>(`${controller}`, {
    params: {
      userId: userId,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getUserResource = async (id: number) => {
  return http.get<IUserResource>(`${controller}/${id}`);
};
export const getUserResourceByEmp = async (employeeId: number) => {
  return http.get<IUserResource>(`${controller}/get-by-employee`, { params: { employeeId } });
};
export const getUserResourceFullAccess = async () => {
  return http.get<IUserResourceFullAccess>(`${controller}/get-full-access`);
};

export const addUserResource = async (userResource: UserResourceAdd) => {
  return http.post<IUserResource>(controller, userResource);
};

export const updateUserResource = async (id: number, userResource: IUserResource) => {
  return await http.put<IUserResource[]>(`${controller}/${id}`, userResource);
};
export const userResourceCopy = async (userResource: UserResourceCopyAdd) => {
  return await http.put<UserResourceCopyAdd>(`${controller}/sync-to`, userResource);
};

export const deleteUserResource = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};

export const getUserResourcesDropdown = async () => {
  return await http.get<IUserResource[]>(`${controller}/dropdown`);
};
