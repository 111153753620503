import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { CSSProperties, useEffect, useState } from 'react';
import { getPositionsDropdown } from 'src/apis/master_data/position.api';
type Props = {
  isShowsOptionNull?: boolean;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  style?: CSSProperties | undefined;
  allowClear: boolean | undefined;
  onChange: (value: string) => void;
};

export default function PositionSelectV3({
  isShowsOptionNull,
  onChange,
  style,
  disabled,
  placeholder,
  allowClear,
  value
}: Props) {
  const [options, setOptions] = useState<DefaultOptionType[] | undefined>([]);

  const positionsDropdownV2Query = useQuery({
    queryKey: ['positionDropdownV2'],
    queryFn: () => {
      return getPositionsDropdown();
    },
    staleTime: 60 * 1000
  });

  useEffect(() => {
    const result = positionsDropdownV2Query.data?.data.map((data) => ({ value: String(data.id), label: data.name }));
    if (result && isShowsOptionNull) {
      result?.unshift({
        value: '0',
        label: 'Không có chức vụ'
      });
    }

    setOptions(result);
  }, [positionsDropdownV2Query, isShowsOptionNull]);

  return (
    <Select
      allowClear={allowClear}
      value={positionsDropdownV2Query.isFetching ? undefined : value}
      style={style}
      disabled={disabled}
      onChange={onChange}
      options={options}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={positionsDropdownV2Query.isFetching}
      placeholder={placeholder}
    />
  );
}
