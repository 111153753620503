import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getTicketCategoriesAvailableToRaiseDropdown } from 'src/apis/helpdesk/ticketCategory.api';
import { TicketTypeEn } from 'src/constants/ticketType';
import { SelectProps } from 'src/types/elements.type';

type Props = SelectProps & {
  onChange?: ((value: string) => void) | undefined;
  ticketTypeId?: number;
  ticketCategoryGroupId?: number;
};

export default function TicketCategoryAvailableToRaiseSelect({
  value,
  onChange,
  style,
  allowClear = true,
  ticketTypeId,
  ticketCategoryGroupId,
  returnValueType = 'id'
}: Props) {
  const ticketCategoriesAvailableToRaiseSelectQuery = useQuery({
    queryKey: ['ticketCategoriesAvailableToRaiseDropdown', ticketTypeId, ticketCategoryGroupId],
    queryFn: () => getTicketCategoriesAvailableToRaiseDropdown(ticketTypeId, ticketCategoryGroupId),
    staleTime: 60 * 1000,
    enabled: ticketTypeId === TicketTypeEn.Issue ? !!ticketCategoryGroupId && !!ticketTypeId : !!ticketTypeId
  });

  return (
    <Select
      allowClear={allowClear}
      value={ticketCategoriesAvailableToRaiseSelectQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      maxTagCount={4}
      options={ticketCategoriesAvailableToRaiseSelectQuery.data?.data.map((item) => {
        return {
          value: returnValueType === 'object' ? JSON.stringify(item) : item[returnValueType],
          label: item.name
        };
      })}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={ticketCategoriesAvailableToRaiseSelectQuery.isFetching}
    />
  );
}
