import { useQuery } from '@tanstack/react-query';
import { Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { getResourceFile } from 'src/apis/master_data/resource.api';
import Icon from 'src/components/v2/Icon';
type Props = { filePath: string; size?: number; style?: React.CSSProperties; className?: string };

function ResourceThumbnail({ filePath, size = 40, style, className }: Props) {
  const [src, setUrl] = useState<string>();

  const ticketAttachmentQuery = useQuery({
    queryKey: ['resourceAttachmentImage', filePath],
    queryFn: () => getResourceFile(filePath),
    enabled: !!filePath,
    staleTime: 60 * 1000
  });

  useEffect(() => {
    if (ticketAttachmentQuery.data) {
      const url = URL.createObjectURL(new Blob([ticketAttachmentQuery.data.data]));
      setUrl(url);
    }
  }, [ticketAttachmentQuery.data]);

  return ticketAttachmentQuery.isFetching ? (
    <Skeleton.Avatar
      active={ticketAttachmentQuery.isFetching}
      style={{ width: size, height: size, borderRadius: 8, display: 'flex' }}
      shape={'square'}
    />
  ) : (
    <Icon style={style} className={`thumbnail-icon ${className}`} size={size} url={src} />
  );
}

export default ResourceThumbnail;
