import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { ReactNode } from 'react';
import { getDriversDropdown } from 'src/apis/master_data/employee.api';
import { SelectProps } from 'src/types/elements.type';

type Props = SelectProps & {
  placeholder?: ReactNode;
  size?: SizeType;
  dateCheck?: Date;
};

export default function DriverSelect({
  value,
  style,
  allowClear = true,
  onChange,
  placeholder,
  size = 'middle',
  dateCheck
}: Props) {
  const employeesDropdownQuery = useQuery({
    queryKey: ['employeesDropdown', dateCheck],
    queryFn: () => getDriversDropdown(dateCheck),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={employeesDropdownQuery.isFetching ? undefined : value}
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      options={employeesDropdownQuery.data?.data.map((item) => ({
        value: item.id,
        label: `${item.code} - ${item.name}`
      }))}
      size={size}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={employeesDropdownQuery.isFetching}
    />
  );
}
