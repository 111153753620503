import type { FlatItem } from 'src/types/elements.type';
import { ILeaveType } from 'src/types/hr_ticket/leaveType.type';
import { http } from 'src/utils/http';

const controller = 'hr/leave-types';

export const getLeaveTypes = async () => {
  return await http.get<ILeaveType[]>(controller);
};

export const getLeaveTypesDropdown = async () => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
};
