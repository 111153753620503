import { Spin } from 'antd';
import { useEffect } from 'react';
import { signoutRedirect } from 'src/apis/auth.api';

export default function SignoutOidc() {
  useEffect(() => {
    signoutRedirect();
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Spin tip='Logging out' size='large' />
    </div>
  );
}
