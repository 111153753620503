import { IShift, ShiftAdd } from 'src/types/master_data/shift.type';
import { http } from 'src/utils/http';

const controller = 'master-data/shifts';

export const getShifts = async () => {
  return await http.get<IShift[]>(`${controller}/get-all`);
};

export const getShift = async (id: number) => {
  return http.get<IShift>(`${controller}/${id}`);
};

export const addShift = async (shift: ShiftAdd) => {
  return http.post<IShift>(controller, shift);
};

export const updateShift = async (id: number, shift: IShift) => {
  return await http.put<IShift[]>(`${controller}/${id}`, shift);
};

export const deleteShift = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};
