import { Button, Flex, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons';

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <Result
      status='404'
      title='404'
      style={{ marginTop: '50px' }}
      subTitle='Sorry, the page you visited does not exist.'
      extra={
        <Flex gap={5} justify='center'>
          <Button type='primary' icon={<ArrowLeftOutlined />} onClick={() => navigate(-1)}>
            Back
          </Button>
          <Button type='primary' icon={<HomeOutlined />} onClick={() => navigate('/')}>
            Home
          </Button>
        </Flex>
      }
    />
  );
}
