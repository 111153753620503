import { Skeleton, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import React, { CSSProperties } from 'react';
import './icon.scss';

interface Props {
  url: string | React.ReactNode;
  size?: number | string;
  titleTooltip?: string;
  colorTooltip?: string;
  arrowTooltip?: boolean;
  styleTooltip?: React.CSSProperties;
  placementTooltip?: TooltipPlacement;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
  style?: React.CSSProperties;
  loading?: boolean;
  disable?: boolean;
  open?: boolean;
}

const Icon = ({
  url,
  size = 22,
  titleTooltip,
  colorTooltip = '#4f606b',
  arrowTooltip = true,
  styleTooltip,
  onClick: handleClick,
  className,
  style,
  loading,
  disable,
  open,
  placementTooltip
}: Props) => {
  const imgStyle: CSSProperties = {
    width: size,
    height: size
    // cursor: 'pointer'
  };
  // const styles = {
  //   ...imgStyle,
  //   cursor: disable ? 'not-allowed' : 'pointer',
  //   opacity: disable ? 0.5 : 1
  // };

  const icon = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        ...style
      }}
      // onClick={disable ? undefined : handleClick}
      className={`icon-custom  ${className} ${disable ? 'disabled' : ''}`}
    >
      {typeof url === 'string' ? (
        <img src={url} alt={url} style={imgStyle} />
      ) : (
        <div style={{ ...imgStyle, fontSize: size }}>{url}</div>
      )}
    </div>
  );

  // if (!titleTooltip) return icon;

  return (
    <Tooltip
      title={titleTooltip}
      color={colorTooltip}
      arrow={arrowTooltip}
      open={open}
      overlayInnerStyle={styleTooltip}
      className='custom-icon-tooltip'
      placement={placementTooltip}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          ...style
        }}
        onClick={(e) => {
          if (disable) return;
          handleClick && handleClick(e);
        }}
        className={className}
      >
        {loading ? <Skeleton.Avatar active shape='circle' size={'large'} /> : icon}
      </div>
    </Tooltip>
  );
};

export default Icon;
