import { User, UserManager } from 'oidc-client';
import authConfig from 'src/constants/authConfig';
import { clearLS, getProfileFromLS } from 'src/utils/auth';

const userManager = new UserManager(authConfig);

export function signinRedirect(pathname?: string) {
  userManager.clearStaleState();
  return userManager.signinRedirect(pathname && { state: { pathname: pathname } });
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback();
}

export function renewToken() {
  return userManager.signinSilent();
}

export async function signoutRedirect() {
  const user: User = getProfileFromLS();
  userManager.clearStaleState();
  userManager.removeUser();
  clearLS();
  return userManager.signoutRedirect({ id_token_hint: user?.id_token });
}

export function signoutRedirectCallback() {
  userManager.clearStaleState();
  userManager.removeUser();
  clearLS();
  return userManager.signoutRedirectCallback();
}

export function getUser() {
  const user = userManager.getUser();
  return user;
}

export async function getToken() {
  const user = await getUser();
  return user && user.access_token;
}
