import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { ReactNode } from 'react';
import { getCashFlowsDropdown } from 'src/apis/hr_ticket/cashFlow.api';

export type OptionType = {
  value: number;
  label: string;
};

interface Props {
  value?: number | undefined;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  onChange?: (value: number, option: OptionType | OptionType[]) => void;
  disabled?: boolean;
  placeholder?: ReactNode;
}

export default function CashFlowSelect({ value, style, allowClear = true, onChange, placeholder, disabled }: Props) {
  const cashFlowsQuery = useQuery({
    queryKey: ['cashFlows'],
    queryFn: () => getCashFlowsDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={cashFlowsQuery.isFetching ? undefined : value}
      style={{ width: '100%', ...style }}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      options={cashFlowsQuery.data?.data.map((data) => ({
        value: data.id,
        label: data.name
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={cashFlowsQuery.isFetching}
    />
  );
}
