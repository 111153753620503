import { lazy } from 'react';
import { PermissionEn } from 'src/constants/permission.enum';
import preSalePath from 'src/constants/urlPath/crm/preSalePath';
import type { RouteType } from 'src/hooks/useRouteElements';
import NewLayout from '../../layouts/NewFeatures/MainLayout';

const PreSale = lazy(() => import('src/pages/CRM/PreSale'));
const Lead = lazy(() => import('src/pages/CRM/PreSale/Lead'));
const LeadForm = lazy(() => import('src/pages/CRM/PreSale/Lead/LeadForm'));
const WorkAssign = lazy(() => import('src/pages/ERP/Planning/Kpi/WorkAssign'));
const WorkAssignAdd = lazy(() => import('src/pages/ERP/Planning/Kpi/AddWorkAssign'));
const WorkAssignDetail = lazy(() => import('src/pages/ERP/Planning/Kpi/WorkAssignDetail'));
const WorkPlan = lazy(() => import('src/pages/ERP/Planning/Kpi/WorkPlan'));
const WorkPlanAdd = lazy(() => import('src/pages/ERP/Planning/Kpi/AddWorkPlan'));
const WorkPlanDetail = lazy(() => import('src/pages/ERP/Planning/Kpi/WorkPlanDetail'));

const preSaleRoutes: RouteType[] = [
  {
    path: preSalePath.preSale,
    Layout: NewLayout,
    Component: PreSale
  },
  // Lead
  {
    path: preSalePath.lead,
    Layout: NewLayout,
    Component: Lead,
    checkPath: preSalePath.lead,
    permission: PermissionEn.read
  },
  {
    path: `${preSalePath.leadDetail}/:id`,
    Layout: NewLayout,
    Component: LeadForm,
    checkPath: preSalePath.lead,
    permission: PermissionEn.viewDetail
  },
  {
    path: `${preSalePath.leadEdit}/:id`,
    Layout: NewLayout,
    Component: LeadForm,
    checkPath: preSalePath.lead,
    permission: PermissionEn.update
  },
  // Work assign
  {
    path: preSalePath.preSaleWorkAssign,
    Layout: NewLayout,
    Component: WorkAssign,
    permission: PermissionEn.read
  },
  {
    path: preSalePath.preSaleWorkAssignAdd,
    Layout: NewLayout,
    Component: WorkAssignAdd,
    permission: PermissionEn.create
  },
  {
    path: `${preSalePath.preSaleWorkAssignDetail}/:id`,
    Layout: NewLayout,
    Component: WorkAssignDetail,
    permission: PermissionEn.viewDetail
  },
  {
    path: `${preSalePath.preSaleWorkAssignEdit}/:id`,
    Layout: NewLayout,
    Component: WorkAssignAdd,
    permission: PermissionEn.update
  },
  // Work plan
  {
    path: preSalePath.preSaleWorkPlan,
    Layout: NewLayout,
    Component: WorkPlan,
    permission: PermissionEn.read
  },
  {
    path: preSalePath.preSaleWorkPlanAdd,
    Layout: NewLayout,
    Component: WorkPlanAdd,
    permission: PermissionEn.create
  },
  {
    path: `${preSalePath.preSaleWorkPlanDetail}/:id`,
    Layout: NewLayout,
    Component: WorkPlanDetail,
    permission: PermissionEn.viewDetail
  },
  {
    path: `${preSalePath.preSaleWorkPlanEdit}/:id`,
    Layout: NewLayout,
    Component: WorkPlanAdd,
    permission: PermissionEn.update
  }
];

export default preSaleRoutes;
