import type { FlatItem } from 'src/types/elements.type';
import {
  ITicketCategory,
  ITicketCategoryShort,
  TicketCategoryAdd,
  TicketCategoryToRaise,
  categoryFlatItem
} from 'src/types/helpdesk/ticketCategory.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'helpdesk/ticket-categories';

export const getTicketCategories = async (
  sequenceStatus: string,
  page: number,
  pageSize: number,
  orderBy?: string,
  departmentId?: number,
  ticketTypeId?: number,
  ticketCategoryGroupId?: number,
  searchCondition?: string
) =>
  await http.get<PagedList<ITicketCategory>>(controller, {
    params: {
      sequenceStatus,
      searchCondition,
      page,
      pageSize,
      orderBy,
      departmentId,
      ticketTypeId,
      ticketCategoryGroupId
    }
  });

export const getTicketCategoriesDropdown = async (departmentId?: number, groupId?: number) =>
  await http.get<FlatItem[]>(`${controller}/dropdown`, {
    params: {
      departmentId,
      groupId
    }
  });

export const getTicketCategoriesAvailableToSetupDropdown = async (ticketCategoryUpdateId?: number | undefined) =>
  await http.get<FlatItem[]>(`${controller}/available-to-setup`, {
    params: {
      ticketCategoryUpdateId
    }
  });

export const getTicketCategoriesAvailableToRaiseDropdown = async (
  ticketTypeId?: number,
  ticketCategoryUpdateId?: number
) =>
  await http.get<TicketCategoryToRaise[]>(`${controller}/available-to-raise`, {
    params: {
      ticketTypeId: ticketTypeId,
      groupId: ticketCategoryUpdateId
    }
  });

export const getTicketCategoriesDropdownV2 = async (
  isApproval: boolean,
  departmentId?: number,
  departmentFilterId?: number,
  groupId?: number,
  ticketTypeId?: number
) =>
  await http.get<categoryFlatItem[]>(`${controller}/dropdown/condition`, {
    params: {
      isApproval,
      departmentId,
      departmentFilterId,
      groupId,
      ticketTypeId
    }
  });

export const getTicketCategoriesDropdownV3 = async (departmentId?: number, groupId?: number) =>
  await http.get<ITicketCategoryShort[]>(`${controller}/dropdown`, {
    params: {
      departmentId,
      groupId
    }
  });

export const getTicketCategory = async (id: number) => await http.get<ITicketCategory>(`${controller}/${id}`);

export const addTicketCategory = async (ticketCategory: TicketCategoryAdd) =>
  await http.post(controller, ticketCategory);

export const updateTicketCategory = async (id: number, ticketCategory: TicketCategoryAdd) =>
  await http.put(`${controller}/${id}`, ticketCategory);

export const deleteTicketCategory = async (id: number) => await http.delete(`${controller}/${id}`);
