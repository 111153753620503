import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getLeaveStatusesDropdown } from 'src/apis/hr_ticket/leaveStatus.api';
import { SelectProps } from 'src/types/elements.type';
import './LeaveStatus.scss';

type Props = SelectProps & {
  leaveStatusDisable?: number[];
};

export default function LeaveStatusSelect({
  value,
  style,
  allowClear = true,
  onChange,
  placeholder,
  leaveStatusDisable,
  disabled
}: Props) {
  const leaveStatusQuery = useQuery({
    queryKey: ['leaveStatus'],
    queryFn: () => getLeaveStatusesDropdown(),
    staleTime: 60 * 1000
  });

  const newValue = leaveStatusQuery.data?.data.find((item) => Number(item.id) === Number(value));

  return (
    <Select
      rootClassName='leave-status-select'
      allowClear={allowClear}
      value={leaveStatusQuery.isFetching ? undefined : JSON.stringify(newValue)}
      style={style}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      options={leaveStatusQuery.data?.data.map((data) => ({
        value: JSON.stringify(data),
        label: data.name,
        disabled: leaveStatusDisable?.includes(data.id) ? true : false
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={leaveStatusQuery.isFetching}
    />
  );
}
