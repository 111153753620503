import { lazy } from 'react';
import inventoryPath from 'src/constants/urlPath/erp/inventoryPath';
import type { RouteType } from 'src/hooks/useRouteElements';
import NewLayout from '../../layouts/NewFeatures/MainLayout';

const Inventory = lazy(() => import('src/pages/Inventory'));
const ITR = lazy(() => import('src/pages/Inventory/ITR'));
const ITRForm = lazy(() => import('src/pages/Inventory/ITR/ITRForm'));

const IT = lazy(() => import('src/pages/Inventory/IT'));
const ITForm = lazy(() => import('src/pages/Inventory/IT/ITForm'));

const MRP = lazy(() => import('src/pages/ProjectPlanning/MRP'));

const inventoryRoutes: RouteType[] = [
  {
    path: inventoryPath.inventory,
    Layout: NewLayout,

    Component: Inventory
  },
  // ITR - Phiếu yêu cầu chuyển kho
  {
    path: inventoryPath.itr,
    Layout: NewLayout,

    Component: ITR
  },
  {
    path: inventoryPath.itrAdd,
    Layout: NewLayout,
    Component: ITRForm
  },
  {
    path: `${inventoryPath.itrEdit}/:code`,
    Layout: NewLayout,
    Component: ITRForm
  },
  {
    path: `${inventoryPath.itrDetailCode}/:code`,
    Layout: NewLayout,
    Component: ITRForm
  },

  // IT - Phiếu chuyển kho
  {
    path: inventoryPath.it,
    Layout: NewLayout,
    Component: IT
  },
  {
    path: inventoryPath.itAdd,
    Layout: NewLayout,
    Component: ITForm
  },
  {
    path: `${inventoryPath.itEdit}/:id`,
    Layout: NewLayout,
    Component: ITForm
  },
  {
    path: `${inventoryPath.itDetailCode}/:code`,
    Layout: NewLayout,
    Component: ITForm
  },
  {
    path: inventoryPath.inventoryMrp,
    Layout: NewLayout,
    Component: MRP
  }
];

export default inventoryRoutes;
