import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useEffect, useState } from 'react';
import { getShortEmployeesDropdown } from 'src/apis/master_data/employee.api';
import { SelectProps } from 'src/types/elements.type';

type Props = SelectProps & {
  value?: string[];
  maxTagCount?: number;
  onChange: ((value: string[], option: DefaultOptionType | DefaultOptionType[]) => void) | undefined;
};

interface OptionType {
  label: React.ReactNode;
  value?: string | number | null;
}

interface OptionGroupType {
  label: React.ReactNode;
  departmentId?: number | undefined;
  options?: Omit<OptionType, 'children'>[];
}

export default function ShortEmployeeGroupByDepartmentMultipleSelect({
  value,
  style,
  allowClear = true,
  onChange,
  placeholder,
  maxTagCount = undefined
}: Props) {
  const [optionsGroup, setOptionsGroup] = useState<OptionGroupType[]>([]);
  const shortEmployeesDropdownQuery = useQuery({
    queryKey: ['shortEmployeesDropdown', undefined],
    queryFn: () => getShortEmployeesDropdown(undefined, undefined, undefined),
    staleTime: 60 * 1000 * 1000
  });

  useEffect(() => {
    if (shortEmployeesDropdownQuery.data?.data) {
      const newOptionsGroup: OptionGroupType[] = [];
      shortEmployeesDropdownQuery.data?.data.forEach((item) => {
        const index = newOptionsGroup.findIndex((optionGroup) => optionGroup.departmentId === item.department?.id);

        if (index === -1) {
          newOptionsGroup.push({
            label: item.department?.name,
            departmentId: item.department?.id,
            options: [
              {
                label: `${item.code} - ${item.fullName}`,
                value: JSON.stringify(item)
              }
            ]
          });
        } else {
          newOptionsGroup[index].options?.push({
            label: `${item.code} - ${item.fullName}`,
            value: JSON.stringify(item)
          });
        }
      });

      setOptionsGroup(newOptionsGroup);
    }
  }, [shortEmployeesDropdownQuery.data?.data]);

  return (
    <Select
      mode='multiple'
      maxTagCount={maxTagCount}
      allowClear={allowClear}
      value={shortEmployeesDropdownQuery.isFetching ? undefined : value}
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      options={optionsGroup}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={shortEmployeesDropdownQuery.isFetching}
    />
  );
}
