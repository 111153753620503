import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getAdvancePaymentEmployeesDropdown } from 'src/apis/hr_ticket/advancePaymentEmployee.api';
import { SelectProps } from 'src/types/elements.type';

type Props = SelectProps & {
  departmentId?: number;
};

export default function AdvancePaymentEmployeeSelect({
  value,
  style,
  allowClear = true,
  onChange,
  departmentId,
  placeholder
}: Props) {
  const advancePaymentEmployeesDropdownQuery = useQuery({
    queryKey: ['advancePaymentEmployeesDropdown'],
    queryFn: () => getAdvancePaymentEmployeesDropdown(departmentId),
    staleTime: 60 * 1000,
    enabled: !!departmentId
  });

  const convertValue = advancePaymentEmployeesDropdownQuery.data?.data
    ? advancePaymentEmployeesDropdownQuery.data?.data.find((item) => String(item.code) === String(value))
    : undefined;

  return (
    <Select
      allowClear={allowClear}
      value={advancePaymentEmployeesDropdownQuery.isFetching ? undefined : JSON.stringify(convertValue)}
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      options={advancePaymentEmployeesDropdownQuery.data?.data.map((data) => ({
        value: JSON.stringify(data),
        label: `${data.code} - ${data.name}`
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={advancePaymentEmployeesDropdownQuery.isFetching}
    />
  );
}
