import { CalendarOutlined, CaretDownOutlined, KeyOutlined, LogoutOutlined } from '@ant-design/icons';
import { Button, Dropdown, Layout, type MenuProps } from 'antd';
import { Fragment, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Add from 'src/assets/icons/add.png';
import User from 'src/assets/icons/user.png';
import Logo from 'src/assets/images/logo.png';
import Icon from 'src/components/v2/Icon';
import urlPath from 'src/constants/urlPath/urlPath';
import { AppContext } from 'src/contexts/app.context';
import { useBasePath } from 'src/hooks/useBasePath';
import ChangePassword from 'src/pages/Config/ChangePassword';
import Config from 'src/pages/v2/Config/Setting';
import TicketManagementModal from 'src/pages/v2/Home/TicketManagementModal';
import styles from './Header.module.scss';
import Notification from './Notification';

const { Header: HeaderAntd } = Layout;

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpenAddTicket, setIsOpenAddTicket] = useState<boolean>(false);
  const basePath = useBasePath();
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);
  const [openConfig, setOpenConfig] = useState<boolean>(false);
  const isTicketPage = location.pathname.includes(urlPath.ticket.location);
  const isHrTicketPage = location.pathname.includes(urlPath.hrTicket.location);
  const isOfficeTicketPage = location.pathname.includes(urlPath.corporateOperation.location);
  const isExternalPage = location.pathname === urlPath.homeSub.location || location.pathname.includes('/s/');
  const isOvertimeTicketPage = basePath?.includes(urlPath.overtimeTicket.location);
  const isBusinessTripTicketPage = basePath?.includes(urlPath.businessTripTicket.location);
  const { userInfo } = useContext(AppContext);

  const handleNavigateBiometricLog = () => {
    navigate(urlPath.biometricLog.location);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      onClick: () => {
        handleNavigateBiometricLog();
      },
      label: (
        <div className={styles['header__act-auth-item']}>
          <CalendarOutlined />
          <span>Nhật kí chấm công</span>
        </div>
      )
    },
    {
      key: '2',
      onClick: () => {
        handleChangePassword();
      },
      label: (
        <div className={styles['header__act-auth-item']}>
          <KeyOutlined />
          <span>Thay đổi mật khẩu</span>
        </div>
      )
    },
    {
      key: '3',
      onClick: () => {
        handleLogout();
      },
      label: (
        <div className={styles['header__act-auth-item']}>
          <LogoutOutlined />
          <span>Đăng xuất</span>
        </div>
      )
    }
  ];

  const handleAdd = () => {
    const path = location.pathname;

    switch (true) {
      case path.includes(urlPath.ticket.location):
        setIsOpenAddTicket(true);
        break;
      case path.includes(urlPath.leaveTicket.location):
        navigate(urlPath.leaveTicketAdd.location);
        break;
      case path.includes(urlPath.advancePaymentTicket.location):
        navigate(urlPath.advancePaymentTicketAdd.location);
        break;
      case path.includes(urlPath.overtimeTicket.location):
        navigate(urlPath.overtimeTicketAdd.location);
        break;
      case path.includes(urlPath.businessTripTicket.location):
        navigate(urlPath.businessTripTicketAdd.location);
        break;
      case path.includes(urlPath.purchaseRequestTicket.location):
        navigate(urlPath.purchaseRequestTicketAdd.location);
        break;
      case path.includes(urlPath.transportationTicket.location):
        navigate(urlPath.transportationTicketAdd.location);
        break;
      case path.includes(urlPath.handoverTicket.location):
        navigate(urlPath.handoverTicketAdd.location);
        break;
      case path.includes(urlPath.equipmentSupplyRequestTicket.location):
        navigate(urlPath.equipmentSupplyRequestTicketAdd.location);
        break;
      case path.includes(urlPath.additionalWorkingTimeTicket.location):
        navigate(urlPath.additionalWorkingTimeTicketAdd.location);
        break;
      case path.includes(urlPath.leaveTicketSub.location):
        navigate(urlPath.leaveTicketAddSub.location);
        break;
      case path.includes(urlPath.externalWorkerTicket.location):
        navigate(urlPath.externalWorkerTicketAdd.location);
        break;
      default:
        break;
    }
  };

  // const handleAdd = () => {
  //   if (location.pathname.includes(urlPath.ticket.location)) {
  //     setIsOpenAddTicket(true);
  //   } else if (location.pathname.includes(urlPath.leaveTicket.location)) {
  //     navigate(urlPath.leaveTicketAdd.location);
  //   } else if (location.pathname.includes(urlPath.advancePaymentTicket.location)) {
  //     navigate(urlPath.advancePaymentTicketAdd.location);
  //   } else if (location.pathname.includes(urlPath.overtimeTicket.location)) {
  //     navigate(urlPath.overtimeTicketAdd.location);
  //   } else if (location.pathname.includes(urlPath.businessTripTicket.location)) {
  //     navigate(urlPath.businessTripTicketAdd.location);
  //   } else if (location.pathname.includes(urlPath.purchaseRequestTicket.location)) {
  //     navigate(urlPath.purchaseRequestTicketAdd.location);
  //   } else if (location.pathname.includes(urlPath.transportationTicket.location)) {
  //     navigate(urlPath.transportationTicketAdd.location);
  //   } else if (location.pathname.includes(urlPath.handoverTicket.location)) {
  //     navigate(urlPath.handoverTicketAdd.location);
  //   } else if (location.pathname.includes(urlPath.equipmentSupplyRequestTicket.location)) {
  //     navigate(urlPath.equipmentSupplyRequestTicketAdd.location);
  //   } else if (location.pathname.includes(urlPath.additionalWorkingTimeTicket.location)) {
  //     navigate(urlPath.additionalWorkingTimeTicketAdd.location);
  //   } else if (location.pathname.includes(urlPath.leaveTicketSub.location)) {
  //     navigate(urlPath.leaveTicketAddSub.location);
  //   }
  // };

  const handleChangePassword = () => {
    setOpenChangePassword(true);
  };

  const handleLogout = async () => {
    const returnUrl = window.location.pathname;
    localStorage.setItem('returnUrl', returnUrl);
    navigate(urlPath.signoutOidc.location);
  };

  const formatName = (fullName: string | undefined) => {
    if (!fullName) return '';

    const newName = fullName?.split(' ').slice(-1).join(' ');
    return newName[0] + newName.slice(1).toLowerCase();
  };

  return (
    <Fragment>
      <HeaderAntd className={styles.header}>
        <div className={styles.header__brand}>
          <Icon
            className={styles['header__brand-logo']}
            url={Logo}
            size={40}
            onClick={() => navigate(isExternalPage ? '/s' : '/')}
          />
          <h1 className={styles['header__brand-title']}>Martech Company</h1>
        </div>
        {!isExternalPage && (
          <div className={styles.header__act}>
            {(isTicketPage || isHrTicketPage || isOfficeTicketPage) &&
              !isOvertimeTicketPage &&
              !isBusinessTripTicketPage && (
                <Button className={styles['header__act-add']} onClick={handleAdd}>
                  <img src={Add} alt='Create a ticket' className={styles['header__act-add-icon']} />
                  Tạo phiếu mới
                </Button>
              )}
            <Notification />
            <Dropdown menu={{ items }} trigger={['click']} arrow={false}>
              <div className={styles['header__act-auth']}>
                <div className={styles['header__act-auth-left']}>
                  <Icon url={User} size={16} />
                  <span className={styles['header__act-auth-name']}>Chào, {formatName(userInfo?.fullName)}</span>
                </div>
                <CaretDownOutlined />
              </div>
            </Dropdown>
          </div>
        )}
      </HeaderAntd>

      <ChangePassword open={openChangePassword} setOpen={setOpenChangePassword} />
      <Config open={openConfig} setOpen={setOpenConfig} />
      {isOpenAddTicket && <TicketManagementModal setIsOpen={setIsOpenAddTicket} isOpen={isOpenAddTicket} />}
    </Fragment>
  );
};

export default Header;
