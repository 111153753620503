import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getNationalsDropdown } from 'src/apis/master_data/national.api';
import { type SelectProps } from 'src/types/elements.type';

export default function NationalSelect({
  value,
  onChange,
  style,
  allowClear = true,
  placeholder,
  disabled,
  returnValueType = 'id'
}: SelectProps) {
  const nationalsDropdownQuery = useQuery({
    queryKey: ['nationalsDropdown'],
    queryFn: () => getNationalsDropdown(),
    staleTime: 60 * 1000
  });

  // const newValue = nationalsDropdownQuery.data?.data.find((item) => String(item.id) === String(value));

  return (
    <Select
      // notFoundContent='Không có sự kiện nào được duyệt'
      disabled={disabled}
      placeholder={placeholder}
      allowClear={allowClear}
      value={nationalsDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={nationalsDropdownQuery.data?.data.map((data) => ({
        value: returnValueType === 'object' ? JSON.stringify(data) : String(data[returnValueType]),
        label: data.name
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={nationalsDropdownQuery.isFetching}
    />
  );
}
