import { useQuery } from '@tanstack/react-query';
import { Select, Space, type SelectProps } from 'antd';
import { useState } from 'react';
import { getModelsByItemShort, getModelsDropdown } from 'src/apis/item_master_data/model.api';
import { CategoryEnum } from 'src/constants/item_master_data/itemMasterData.enum';

type Props = SelectProps & {
  category?: number;
  itemId?: number;
  valueType?: 'id' | 'code';
  filterWidth?: number;
};

export default function ModelSelect({
  value,
  onChange,
  style,
  allowClear = true,
  placeholder,
  disabled,
  category,
  itemId,
  valueType = 'id',
  filterWidth = 158,
  ...props
}: Props) {
  const [categoryId, setCategoryId] = useState<CategoryEnum>(category || CategoryEnum.NVL);

  const modelsDropdownQuery = useQuery({
    queryKey: ['modelsDropdown', categoryId, itemId],
    queryFn: () => (!!itemId ? getModelsByItemShort(Number(itemId)) : getModelsDropdown(Number(categoryId))),
    staleTime: 60 * 1000,
    enabled: !!categoryId || !!itemId
  });

  return (
    <Space.Compact block>
      {!category && (
        <Select
          style={{ width: filterWidth }}
          value={categoryId}
          options={[
            { label: 'Nguyên vật liệu', value: CategoryEnum.NVL },
            { label: 'Đối tác', value: CategoryEnum.BP },
            { label: 'Thành phẩm hàng hóa', value: CategoryEnum.TPHH }
          ]}
          onChange={(value) => setCategoryId(value)}
          placeholder='Chọn loại thành phẩm'
        />
      )}

      <Select
        disabled={disabled}
        allowClear={allowClear}
        value={modelsDropdownQuery.isFetching ? undefined : value}
        style={style}
        onChange={onChange}
        options={modelsDropdownQuery.data?.data.map((item) => ({
          value: item[valueType],
          label: item.name,
          data: item
        }))}
        showSearch
        filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
        placeholder={placeholder}
        loading={modelsDropdownQuery.isFetching}
        {...props}
      />
    </Space.Compact>
  );
}
