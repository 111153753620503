import { PagedList } from 'src/types/pagedList.type';
import { ITicketSolution, TicketSolutionAdd } from 'src/types/helpdesk/ticketSolution.type';
import { http } from 'src/utils/http';

const controller = 'helpdesk/ticket-solutions';

export const getTicketSolutions = async (sequenceStatus: string, page: number, pageSize: number, orderBy?: string) =>
  await http.get<PagedList<ITicketSolution>>(controller, {
    params: {
      sequenceStatus,
      page,
      pageSize,
      orderBy
    }
  });

export const getTicketSolution = async (id: number) => await http.get<ITicketSolution>(`${controller}/${id}`);

export const getTicketSolutionsByTicketId = async (
  ticketId: string,
  page: number,
  pageSize: number,
  orderBy?: string
) =>
  await http.get<PagedList<ITicketSolution>>(`${controller}/get-solutions`, {
    params: {
      ticketId,
      page,
      pageSize,
      orderBy
    }
  });

export const getTicketMySolutions = async (ticketId: string) =>
  await http.get<ITicketSolution[]>(`${controller}/my-solutions`, {
    params: {
      ticketId
    }
  });

export const getTicketSolutionsDropdown = async (ticketId: string) =>
  await http.get<ITicketSolution[]>(`${controller}/dropdown`, {
    params: {
      ticketId
    }
  });

export const addTicketSolution = async (ticketSolution: TicketSolutionAdd) =>
  await http.post(controller, ticketSolution);

export const updateTicketSolution = async (id: number, ticketSolution: TicketSolutionAdd) =>
  await http.put(`${controller}/${id}`, ticketSolution);

export const deleteTicketSolution = async (id: number) => await http.delete(`${controller}/${id}`);

export const approvalTicketSolution = async (id: number, isAssign?: boolean) =>
  await http.put(`${controller}/approval/${id}?isAssign=${isAssign}`);

export const unApprovalTicketSolution = async (id: number) => await http.put(`${controller}/un-approval/${id}`);
