import { notification } from 'antd';
import { v4 as uuid } from 'uuid';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const createNotification = (
  type: NotificationType,
  message: string,
  description: string,
  onClick: () => void,
  key: string
) => {
  notification[type]({
    message,
    description,
    onClick,
    key
  });
};

export const showNotification = (content: string, type: NotificationType = 'info') => {
  const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1);

  const copyToClipboard = async (text: string, key: string) => {
    try {
      await navigator.clipboard.writeText(text);
      notification.destroy(key);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const key = uuid();

  notification.config({
    placement: 'topRight',
    duration: 3,
    maxCount: 3
  });

  createNotification(type, capitalizedType, content, () => copyToClipboard(content, key), key);
};
