import { Flex, Grid, Layout } from 'antd';
import Breadcrumb from 'src/components/v1/Breadcrumb';
import { SearchTicket } from 'src/components/v2/Search';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TicketFilterPopover from 'src/components/v2/Popover/TicketFilterPopover';
import urlPath from 'src/constants/urlPath/urlPath';
import { useBasePath } from 'src/hooks/useBasePath';
import TicketSortPopover from '../Popover/TicketSortPopover';
import './subHeader.scss';
import { getAddPath } from 'src/utils/utils';
const { useBreakpoint } = Grid;
const { Header: HeaderAntd } = Layout;

const SubHeader = ({ hasSubHeader }: { hasSubHeader: boolean }) => {
  const location = useLocation();
  const basePath = useBasePath();
  const screens = useBreakpoint();
  const [, setPath] = useState<string | undefined>('');

  useEffect(() => {
    if (getAddPath(basePath)?.includes('/add') && !basePath?.includes(urlPath.solution.location)) {
      const index = getAddPath(basePath)?.indexOf('/add');
      setPath(getAddPath(basePath)?.slice(0, index));
    } else if (basePath?.includes('/edit')) {
      const index = basePath.indexOf('/edit');
      setPath(basePath.slice(0, index));
    } else {
      setPath('');
    }
  }, [basePath]);

  const renderSearchElement = () => {
    if (
      !getAddPath(basePath)?.includes('/add') &&
      !location.pathname.includes('/edit') &&
      !location.pathname.includes(urlPath.biometricLog.location) &&
      !location.pathname.includes(urlPath.userGuide.location)
    ) {
      return (
        // <Col span={14} className='panel-search'>
        // </Col>
        <Flex justify='end' align='center' gap={20} className='panel-search'>
          <Flex
            justify='end'
            style={{
              width: '70%'
            }}
          >
            <TicketFilterPopover />
          </Flex>
          <Flex>
            <TicketSortPopover />
          </Flex>
          <Flex
            style={{
              width: screens.lg ? '400px' : '250px'
            }}
          >
            <SearchTicket placeholder='Nhập mã hoặc tiêu đề phiếu' className='sub-header-search' />
          </Flex>
        </Flex>
      );
    }
  };

  return (
    <HeaderAntd className='sub-header-ticket'>
      <Flex justify='space-between' align='middle' gap={10} className='sub-header-row'>
        <div className='sub-header-col panel-navigation'>
          <div className='menu-button'>{screens.md && <Breadcrumb className='breadcrumb' />}</div>
        </div>
        {hasSubHeader && renderSearchElement()}
      </Flex>
    </HeaderAntd>
  );
};

export default SubHeader;
