import { useQuery } from '@tanstack/react-query';
import { Select, Typography } from 'antd';
import { getUnitOfCurrenciesDropdown } from 'src/apis/master_data/unitOfCurrency.api';
import { type SelectProps } from 'src/types/elements.type';
import './UnitOfCurrencySelect.scss';

const { Text } = Typography;

type Props = SelectProps & {
  returnValueType: 'id' | 'code';
};

export default function UnitOfCurrencySelect({
  value,
  style,
  allowClear,
  onChange,
  placeholder,
  disabled,
  returnValueType = 'id'
}: Props) {
  const unitOfCurrenciesDropdownQuery = useQuery({
    queryKey: ['unitOfCurrenciesDropdown'],
    queryFn: () => getUnitOfCurrenciesDropdown(),
    staleTime: 60 * 1000 * 60
  });

  return (
    <Select
      rootClassName='unit-of-currency'
      allowClear={allowClear}
      disabled={disabled}
      value={unitOfCurrenciesDropdownQuery.isFetching ? undefined : value}
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      options={unitOfCurrenciesDropdownQuery.data?.data?.map((item) => ({
        label: <Text className={`${item.conversionValue <= 0 && 'disable'}`}>{item.code}</Text>,
        value: item[returnValueType],
        data: item
      }))}
      showSearch
      filterOption={(input, option) => {
        return (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase());
      }}
      loading={unitOfCurrenciesDropdownQuery.isFetching}
    />
  );
}
