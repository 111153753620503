import { User } from 'oidc-client';

export const setAccessTokenToLS = (access_token: string) => {
  localStorage.setItem('access_token', access_token);
};

export const getAccessTokenFromLS = () => {
  return localStorage.getItem('access_token') || '';
};

export const clearLS = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('profile');
  localStorage.removeItem('isAdmin');
};

export const getProfileFromLS = () => {
  const result = localStorage.getItem('profile');
  return result ? JSON.parse(result) : null;
};

export const setProfileToLS = (profile: User) => {
  setAccessTokenToLS(profile.access_token);
  localStorage.setItem('profile', JSON.stringify(profile));
};
