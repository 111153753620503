import { lazy } from 'react';
import rndPath from 'src/constants/urlPath/erp/rndPath';
import type { RouteType } from 'src/hooks/useRouteElements';
import NewLayout from '../../layouts/NewFeatures/MainLayout';

const Rnd = lazy(() => import('src/pages/Rnd'));
const RawMatPmr = lazy(() => import('src/pages/Rnd/RawMatPmr'));
const AddRawMatPmr = lazy(() => import('src/pages/Rnd/RawMatPmr/AddRawMatPmr'));

const LaborPmr = lazy(() => import('src/pages/Rnd/LaborPmr'));
const AddLaborPmr = lazy(() => import('src/pages/Rnd/LaborPmr/AddLaborPmr'));

const EquipmentPmr = lazy(() => import('src/pages/Rnd/EquipmentPmr'));
const AddEquipmentPmr = lazy(() => import('src/pages/Rnd/EquipmentPmr/AddEquipmentPmr'));

const ProductionProcess = lazy(() => import('src/pages/Rnd/ProductionProcess'));
const AddProductionProcess = lazy(() => import('src/pages/Rnd/ProductionProcess/AddProductionProcess'));

const ItemMasterData = lazy(() => import('src/pages/ItemMasterData/ItemMasterData'));
const Model = lazy(() => import('src/pages/ItemMasterData/Model'));
const ItemSpec = lazy(() => import('src/pages/ItemMasterData/ItemSpec'));

const rndRoutes: RouteType[] = [
  {
    path: rndPath.rnd,
    Layout: NewLayout,
    Component: Rnd
  },
  // Raw Material PMR - PMR Nguyên vật liệu
  {
    path: rndPath.rawMatPmr,
    Layout: NewLayout,
    Component: RawMatPmr
  },
  {
    path: rndPath.rawMatPmrAdd,
    Layout: NewLayout,
    Component: AddRawMatPmr
  },
  {
    path: `${rndPath.rawMatPmrEdit}/:id`,
    Layout: NewLayout,
    Component: AddRawMatPmr
  },
  {
    path: `${rndPath.rawMatPmrDetail}/:id`,
    Layout: NewLayout,
    Component: AddRawMatPmr
  },

  // Labor PMR - PMR Nhân công
  {
    path: rndPath.laborPmr,
    Layout: NewLayout,
    Component: LaborPmr
  },
  {
    path: rndPath.laborPmrAdd,
    Layout: NewLayout,
    Component: AddLaborPmr
  },
  {
    path: `${rndPath.laborPmrEdit}/:id`,
    Layout: NewLayout,
    Component: AddLaborPmr
  },
  {
    path: `${rndPath.laborPmrDetailCode}/:code`,
    Layout: NewLayout,
    Component: AddLaborPmr
  },

  // Equipment PMR - PMR công cụ dụng cụ
  {
    path: rndPath.equipmentPmr,
    Layout: NewLayout,
    Component: EquipmentPmr
  },
  {
    path: rndPath.equipmentPmrAdd,
    Layout: NewLayout,
    Component: AddEquipmentPmr
  },
  {
    path: `${rndPath.equipmentPmrEdit}/:id`,
    Layout: NewLayout,
    Component: AddEquipmentPmr
  },
  {
    path: `${rndPath.equipmentPmrDetailCode}/:code`,
    Layout: NewLayout,
    Component: AddEquipmentPmr
  },

  // Production Process - Quy trình sản xuất
  {
    path: rndPath.rndProductionProcess,
    Layout: NewLayout,
    Component: ProductionProcess
  },
  {
    path: rndPath.rndProductionProcessAdd,
    Layout: NewLayout,
    Component: AddProductionProcess
  },
  {
    path: `${rndPath.rndProductionProcessEdit}/:code`,
    Layout: NewLayout,
    Component: AddProductionProcess
  },
  {
    path: `${rndPath.rndProductionProcessDetailCode}/:code`,
    Layout: NewLayout,
    Component: AddProductionProcess
  },

  {
    path: rndPath.itemProductRnd,
    Layout: NewLayout,
    Component: ItemMasterData
  },
  {
    path: rndPath.itemMaterialRnd,
    Layout: NewLayout,
    Component: ItemMasterData
  },
  {
    path: rndPath.modelRnd,
    Layout: NewLayout,
    Component: Model
  },
  {
    path: rndPath.itemSpecRnd,
    Layout: NewLayout,
    Component: ItemSpec
  }
];

export default rndRoutes;
