import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

type RangeValue = [Dayjs | null, Dayjs | null] | null;

interface Props {
  style?: React.CSSProperties | undefined;
  allowClear?: boolean;
  format?: string;
  value?: [dayjs.Dayjs | null, dayjs.Dayjs | null];
  onChange: (values: RangeValue, formatString: [string, string]) => void;
}

export default function RangeDate({ style, allowClear, format = 'DD/MM/YYYY', value, onChange }: Props) {
  const { t } = useTranslation();
  return (
    <RangePicker
      allowClear={allowClear}
      style={style}
      value={value}
      format={format}
      onChange={onChange}
      placeholder={[t('placeholder.base.fDate').toString(), t('placeholder.base.tDate').toString()]}
    />
  );
}
