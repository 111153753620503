import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getTicketCategoriesDropdownV3 } from 'src/apis/helpdesk/ticketCategory.api';
import { SelectProps } from 'src/types/elements.type';

export default function TicketCategoryFilterSelect({
  value,
  onChange,
  style,
  allowClear = true,
  placeholder
}: SelectProps) {
  const ticketCategoriesFilterDropdownQuery = useQuery({
    queryKey: ['ticketCategoriesFilterDropdown'],
    queryFn: () => getTicketCategoriesDropdownV3(),
    staleTime: 60 * 1000 * 60
  });

  return (
    <Select
      allowClear={allowClear}
      style={{ width: '100%', ...style }}
      placeholder={placeholder}
      value={ticketCategoriesFilterDropdownQuery.isFetching ? undefined : value}
      onChange={onChange}
      options={ticketCategoriesFilterDropdownQuery.data?.data.map((data) => ({
        value: JSON.stringify(data),
        label: data.name
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={ticketCategoriesFilterDropdownQuery.isFetching}
    />
  );
}
