import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getVehicleTypesDropdown } from 'src/apis/master_data/vehicleType.api';
import { SelectProps } from 'src/types/elements.type';
import { SizeType } from 'antd/es/config-provider/SizeContext';

type Props = SelectProps & {
  size?: SizeType;
};

export default function VehicleTypeSelect({ value, onChange, style, allowClear, size = 'middle' }: Props) {
  const vehicleTypesDropdownQuery = useQuery({
    queryKey: ['vehicleTypesDropdown'],
    queryFn: () => getVehicleTypesDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={vehicleTypesDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      showSearch
      size={size}
      options={vehicleTypesDropdownQuery.data?.data.map((data) => ({ value: data.id, label: data.name }))}
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={vehicleTypesDropdownQuery.isFetching}
    />
  );
}
