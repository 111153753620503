import { CardType } from 'src/types/elements.type';
import './CustomCard.scss';
import { Flex, Tooltip, Typography } from 'antd';
import Icon from '../Icon';
const { Title, Paragraph } = Typography;

export default function CustomCard({
  image,
  title,
  description,
  action = null,
  layout = 'vertical',
  size = 'medium',
  imageSize = 100,
  hoverable = false,
  style,
  className,
  onClick,
  disable
}: CardType) {
  if (layout === 'vertical') {
    return (
      <Tooltip
        title={
          disable ? `Bạn không có quyền ${description?.toString().toLocaleLowerCase()}. Vui lòng liên hệ MIS!` : ''
        }
      >
        <Flex
          className={`custom-card v-card ${hoverable && 'hoverable'} ${className}`}
          vertical
          align='center'
          justify='center'
          style={{ ...style, cursor: disable ? 'not-allowed' : 'default', opacity: disable ? 0.6 : 1 }}
          onClick={disable ? () => {} : onClick}
        >
          <Icon url={image} size={imageSize} disable={disable} />
          <Title level={4}>{title}</Title>
          <Paragraph className='description'>{description}</Paragraph>
          {action && action?.map((item) => item)}
        </Flex>
      </Tooltip>
    );
  } else
    return (
      <Tooltip
        title={
          disable ? `Bạn không có quyền ${description?.toString().toLocaleLowerCase()}. Vui lòng liên hệ MIS!` : ''
        }
      >
        <Flex
          className={`custom-card h-card ${hoverable ? 'hoverable' : ''} ${disable ? 'disabled' : ''} ${className}`}
          align='center'
          justify='space-between'
          gap={10}
          style={{ ...style, opacity: disable ? 0.6 : 1 }}
          onClick={disable ? () => {} : onClick}
        >
          <Flex justify='flex-start' gap={10}>
            <Icon url={image} size={imageSize} disable={disable} />
            <Flex vertical>
              <Title level={5}>{title}</Title>
              <Paragraph className='description'>{description}</Paragraph>
            </Flex>
          </Flex>
          <Flex vertical justify='space-evenly' className='action-container' style={{ height: '100%' }}>
            {action && action?.map((item) => item)}
          </Flex>
        </Flex>
      </Tooltip>
    );
}
