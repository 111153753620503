import { DriverConfirmEn } from 'src/constants/driverConfirm.enum';
import { HrConfirmTypeEn } from 'src/constants/hrConfirmTypeEn.enum';
import type { RangeDateTypeHr } from 'src/types/date.type';
import type { FlatItem, TicketResponses } from 'src/types/elements.type';
import { ApprovalStatus } from 'src/types/helpdesk/ticket.type';
import type {
  ITransportationAttachFile,
  ITransportationAttachFileAdd,
  ITransportationTicket,
  TransportationTicketAdd,
  TransportationTicketEdit,
  TransportationTicketHrConfirm,
  TransportationTicketHrConfirmDetail
} from 'src/types/hr_ticket/transportationTicket.type';
import type { PagedList } from 'src/types/pagedList.type';
import type { IHrTicketParams } from 'src/types/params.type';
import { http } from 'src/utils/http';

const controller = 'hr/transportation-tickets';

export const getTransportationTickets = async (params: IHrTicketParams) => {
  return await http.get<PagedList<ITransportationTicket>>(controller, {
    params: params
  });
};

export const getTransportationTicketsApproval = async (params: IHrTicketParams) => {
  return await http.get<PagedList<ITransportationTicket>>(`${controller}/waiting-approval`, {
    params: params
  });
};

export const getTransportationTicketsConfirm = async (params: IHrTicketParams) => {
  const newFromDate = (params?.fromDate as Date).toISOString();
  const newToDate = (params?.toDate as Date).toISOString();
  return await http.get<PagedList<ITransportationTicket>>(`${controller}/confirm/${newFromDate}/${newToDate}`, {
    params: params
  });
};

export const getTransportationTicketsMyHandle = async (params: IHrTicketParams) => {
  return await http.get<PagedList<ITransportationTicket>>(`${controller}/driver-handle`, {
    params: params
  });
};

export const getTransportationTicket = async (id: number) => {
  return await http.get<ITransportationTicket>(`${controller}/${id}`);
};

export const getTransportationTicketsDropdown = async () => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
};

export const addTransportationTicket = async (transportationTicket: TransportationTicketAdd) => {
  return await http.post(controller, transportationTicket);
};

export const updateTransportationTicket = async (id: number, transportationTicket: TransportationTicketAdd) => {
  return await http.put(`${controller}/${id}`, transportationTicket);
};

export const updateTransportationTicketAfterConfirm = async (
  id: number,
  transportationTicket: TransportationTicketEdit
) => {
  return await http.put(`${controller}/update-after-confirm/${id}`, transportationTicket);
};

export const deleteTransportationTicket = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};

export const cancelTransportationTicket = async (id: number) => {
  return await http.put(`${controller}/${id}/cancel`);
};

export const approvalTransportationTickets = async (
  ticketResponses: TicketResponses[],
  approvalStatus: ApprovalStatus,
  note?: string
) =>
  await http.put(`${controller}/approvals`, {
    ticketResponses: ticketResponses,
    approvalStatus,
    note: note ? note : ''
  });

export const confirmTransportationTicket = async (
  ticketId: number,
  confirmType: HrConfirmTypeEn,
  confirmNote?: string,
  vehicleId?: number,
  driverId?: number
) =>
  await http.put(`${controller}/confirm-v2`, {
    id: ticketId,
    confirmType,
    confirmNote,
    vehicleId,
    driverId
  });

export const hrConfirmTransportationTicket = async (id: number | undefined, hrConfirm: TransportationTicketHrConfirm) =>
  await http.put(`${controller}/hr-confirm/${id}`, hrConfirm);

export const administrativeConfirmTransportationTicket = async (
  id: number | undefined,
  administrativeConfirm: { administrationConfirms: TransportationTicketHrConfirmDetail[] }
) => await http.put(`${controller}/administrative-confirm/${id}`, administrativeConfirm);

export const driverConfirmTransportationTicket = async (ticketId: number, confirmType: DriverConfirmEn) =>
  await http.put(`${controller}/driver-confirm/${ticketId}?driverConfirmType=${confirmType}`);

export const confirmTransportationTickets = async (
  ticketIds: number[],
  confirmType: HrConfirmTypeEn,
  confirmNote?: string,
  vehicleId?: number
) =>
  await http.put(`${controller}/confirm`, {
    ticketIds,
    confirmType,
    confirmNote,
    vehicleId
  });

export const cancelApprovalTransportationTickets = async (id: string, step: number) =>
  await http.put(`${controller}/${id}/cancel-approval`, { step });

export const printTransportationTicket = async (id: number) =>
  await http.get(`${controller}/print`, {
    params: {
      id: id
    },
    responseType: 'blob'
  });

export const getRefTransportationTicketsForTicket = async (refTicketId: string) =>
  await http.get<ITransportationTicket[]>(`${controller}/get-ref-tickets/${refTicketId}`);

// Other
const controllerV2 = 'hr/transportation-types';

export const getTransportationTypesDropdown = async () => {
  return http.get<FlatItem[]>(`${controllerV2}/dropdown`);
};

const controllerV3 = 'hr/request-types';

export const getRequestTypesDropdown = async () => {
  return await http.get<FlatItem[]>(`${controllerV3}/dropdown`);
};

const controllerV4 = 'hr/transported-object-types';

export const getTransportedObjectTypesDropdown = async () => {
  return await http.get<FlatItem[]>(`${controllerV4}/dropdown`);
};

export const getTransportationWaitingProcessingCount = async (params: RangeDateTypeHr) => {
  return await http.get(`${controller}/count-ticket-waiting-processing`, {
    params: params
  });
};

export const getMyTransportationTicketCount = async (params: RangeDateTypeHr) => {
  return await http.get(`${controller}/count-my-tickets`, {
    params: params
  });
};

export const getTransportationTicketApprovalCount = async (params: RangeDateTypeHr) => {
  return await http.get(`${controller}/count-handle-tickets`, {
    params: params
  });
};

export const getTransportationHrConfirmCount = async (params: RangeDateTypeHr, sequenceTransportTypeId: string) => {
  return await http.get(`${controller}/count-ticket-confirm`, {
    params: {
      ...params,
      sequenceTransportTypeId
    }
  });
};

export const getTransportationTicketFile = async (ticketFile: ITransportationAttachFileAdd) => {
  return await http.get(`${controller}/get-file`, {
    params: {
      fileUrl: ticketFile.filePath,
      fileName: ticketFile.originalName
    },
    responseType: 'blob'
  });
};

export const uploadTransportationTicketFile = async (formData: FormData) => {
  return await http.post<ITransportationAttachFile[]>(`${controller}/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const updateTransportationTicketFile = async (id: number | undefined, paths: string[]) => {
  return await http.put(`${controller}/add-attaches?id=${id}`, paths);
};

export const deleteTransportationTicketFile = async (fileUrls: string[]) =>
  await http.delete(`${controller}/delete-file`, {
    data: fileUrls
  });

export const getTransportationTicketsReport = async (
  fromDate?: Date,
  toDate?: Date,
  departmentId?: number | undefined,
  searchCondition?: string | undefined,
  confirmType?: HrConfirmTypeEn | undefined
) => {
  return await http.get<ITransportationTicket[]>(`${controller}/hr-report`, {
    params: {
      fromDate,
      toDate,
      departmentId,
      searchCondition,
      confirmType
    }
  });
};
