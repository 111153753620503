import { Spin } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signinRedirectCallback } from 'src/apis/auth.api';
import { setProfileToLS } from 'src/utils/auth';

export default function SigninOidc() {
  const navigate = useNavigate();

  const signinAsync = async () => {
    try {
      const profile = await signinRedirectCallback();
      setProfileToLS(profile);
    } catch (error) {
      console.error('Error during signin callback:', error);
      navigate('/error');
    }
  };

  useEffect(() => {
    const executeSignin = async () => {
      await signinAsync();

      const returnUrl = localStorage.getItem('returnUrl');
      const profile = JSON.parse(localStorage.getItem('profile') as string);
      if (profile?.state?.pathname) {
        navigate(profile.state.pathname);
      } else if (returnUrl) {
        navigate(returnUrl);
      } else {
        navigate('/');
      }
    };

    executeSignin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Spin tip='Logging in' size='large' />
    </div>
  );
}
