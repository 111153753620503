import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { getVehiclesDropdown } from 'src/apis/hr_ticket/vehicle.api';
import { SelectProps } from 'src/types/elements.type';

type PropsType = SelectProps & {
  vehicleTypeId?: number;
  size?: SizeType;
};

export default function VehicleSelect({
  value,
  onChange,
  vehicleTypeId,
  style,
  size,
  allowClear = true,
  placeholder
}: PropsType) {
  const vehiclesDropdownQuery = useQuery({
    queryKey: ['vehiclesDropdown', vehicleTypeId],
    queryFn: () => getVehiclesDropdown(vehicleTypeId),
    staleTime: 60 * 1000,
    enabled: !!vehicleTypeId
  });

  return (
    <Select
      placeholder={placeholder}
      allowClear={allowClear}
      value={vehiclesDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      showSearch
      size={size}
      options={vehiclesDropdownQuery.data?.data.map((data) => ({
        value: data.id,
        label: `${data.name} - ${data.licensePlate}`
      }))}
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={vehiclesDropdownQuery.isFetching}
    />
  );
}
