export enum PermissionEn {
  read = 'READ',
  viewDetail = 'VIEW_DETAIL',
  create = 'CREATE',
  update = 'UPDATE',
  delete = 'DELETE',
  approve = 'APPROVE',
  reject = 'REJECT',
  approve_solution = 'APPROVE_SOLUTION',
  assign = 'ASSIGN',
  create_leave_ticket = 'CREATE_LEAVE_TICKET',
  admin = 'ADMIN',
  get_qr = 'GET_QR',
  config_field = 'CONFIG_FIELD',
  confirm_transportation = 'CONFIRM_TRANSPORTATION'
}
