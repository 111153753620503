import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getAdditionalTypesDropdown } from 'src/apis/hr_ticket/additionalType.api';
import { SelectProps } from 'src/types/elements.type';

export default function AdditionalTypeSelect({
  value,
  onChange,
  style,
  allowClear = true,
  placeholder,
  disabled
}: SelectProps) {
  const additionalTypesDropdownQuery = useQuery({
    queryKey: ['additionalTypesDropdown'],
    queryFn: () => getAdditionalTypesDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      disabled={disabled}
      allowClear={allowClear}
      value={additionalTypesDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={additionalTypesDropdownQuery.data?.data.map((data) => ({
        value: JSON.stringify(data),
        label: data.name
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      placeholder={placeholder}
      loading={additionalTypesDropdownQuery.isFetching}
    />
  );
}
