import { Button, Flex, Tooltip, Typography } from 'antd';
import styles from './SubHeader.module.scss';
// @ts-ignore
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { HomeIcon, MenuBarIcon, MenuDotIcon, TurnBackIcon } from 'src/assets/icons';
import Icon from 'src/components/v2/Icon';
import { CRUDPathSegmentEn } from 'src/constants/newFeatures/crudPathSegment.enum';
import { AppContext } from 'src/contexts/app.context';
import { useBasePath } from 'src/hooks/useBasePath';

const { Title } = Typography;

export default function SubHeader() {
  const { setIsSidebarCollapsed, isSidebarCollapsed, currentResource, setOpenAppMenu } = useContext(AppContext);
  const navigate = useNavigate();
  const basePath = useBasePath();

  const handleBack = () => {
    const splitedPath = basePath?.split('/');
    const crudPath = splitedPath?.pop();
    if (
      `/${crudPath}` === CRUDPathSegmentEn.DETAIL_BY_CODE ||
      `/${crudPath}` === CRUDPathSegmentEn.DETAIL ||
      `/${crudPath}` === CRUDPathSegmentEn.ADD ||
      `/${crudPath}` === CRUDPathSegmentEn.EDIT
    ) {
      navigate(`${splitedPath?.join('/')}`);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className={styles['sub-header']}>
      <Flex justify='space-between'>
        <Flex gap={5} align='baseline'>
          <Tooltip title={!isSidebarCollapsed ? 'Đóng Menu' : 'Mở menu'}>
            <Button
              size='large'
              type='text'
              icon={<Icon url={MenuBarIcon} size={20} />}
              onClick={() => {
                setIsSidebarCollapsed((prev) => !prev);
              }}
            />
          </Tooltip>
          <Tooltip title='Quay lại'>
            <Button size='large' type='text' icon={<Icon url={TurnBackIcon} size={20} />} onClick={handleBack} />
          </Tooltip>
          <Tooltip title='Về trang chủ'>
            <Button size='large' type='text' icon={<Icon url={HomeIcon} size={20} />} onClick={() => navigate('/')} />
          </Tooltip>
        </Flex>
        <Button
          size='large'
          type='text'
          icon={<Icon url={MenuDotIcon} size={20} />}
          onClick={() => setOpenAppMenu(true)}
        >
          <Title level={3} className={styles['menu-label']}>
            {currentResource?.parent?.name}
          </Title>
        </Button>
      </Flex>
    </div>
  );
}
