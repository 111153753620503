import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getLeadsDropdown } from 'src/apis/crm/lead.api';
import type { SelectProps } from 'src/types/elements.type';

export default function LeadsSelect({ value, style, onChange, returnValueType, ...props }: SelectProps) {
  const leadsDropdownQuery = useQuery({
    queryKey: ['leadsDropdown'],
    queryFn: () => getLeadsDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      value={leadsDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={leadsDropdownQuery.data?.data.map((item) => ({
        value: returnValueType === 'code' ? item.code : returnValueType === 'object' ? JSON.stringify(item) : item.id,
        label: item.code
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={leadsDropdownQuery.isFetching}
      {...props}
    />
  );
}
