import { CRUDPathSegmentEn } from '../../newFeatures/crudPathSegment.enum';

const preSalePath = {
  preSale: '/crm/pre-sale',

  // Lead
  lead: '/crm/pre-sale/lead',
  leadDetail: '/crm/pre-sale/lead' + CRUDPathSegmentEn.DETAIL,
  leadAdd: '/crm/pre-sale/lead' + CRUDPathSegmentEn.ADD,
  leadEdit: '/crm/pre-sale/lead' + CRUDPathSegmentEn.EDIT,

  // Work assign
  preSaleWorkAssign: '/crm/pre-sale/work-assign',
  preSaleWorkAssignDetail: '/crm/pre-sale/work-assign' + CRUDPathSegmentEn.DETAIL,
  preSaleWorkAssignAdd: '/crm/pre-sale/work-assign' + CRUDPathSegmentEn.ADD,
  preSaleWorkAssignEdit: '/crm/pre-sale/work-assign' + CRUDPathSegmentEn.EDIT,

  // Work Plan
  preSaleWorkPlan: '/crm/pre-sale/work-plan',
  preSaleWorkPlanDetail: '/crm/pre-sale/work-plan' + CRUDPathSegmentEn.DETAIL,
  preSaleWorkPlanAdd: '/crm/pre-sale/work-plan' + CRUDPathSegmentEn.ADD,
  preSaleWorkPlanEdit: '/crm/pre-sale/work-plan' + CRUDPathSegmentEn.EDIT
};
export default preSalePath;
