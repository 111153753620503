import type { FlatItem, HierarchicalItem } from 'src/types/elements.type';
import { DepartmentAdd, IDepartment } from 'src/types/master_data/department.type';
import { http } from 'src/utils/http';

const controller = 'master-data/departments';

export const getDepartments = async (sequenceStatus: string, searchCondition: string | undefined, boardId?: number) => {
  return await http.get<IDepartment[]>(controller, {
    params: {
      sequenceStatus,
      name: searchCondition,
      boardId
    }
  });
};

export const getDepartmentsDropdownByHasProcess = async (isHasProcess: boolean) => {
  return await http.get<HierarchicalItem[]>(`${controller}/gets-by-has-process`, {
    params: { hasProcess: isHasProcess }
  });
};

export const getDepartmentsDropdown = async () => {
  return await http.get<HierarchicalItem[]>(`${controller}/dropdown`);
};

export const getDepartmentChildDropdown = async (departmentParentId: number | undefined) => {
  return await http.get<FlatItem[]>(`${controller}/dropdown/children/${departmentParentId}`);
};

export const getDepartment = async (id: number) => await http.get<IDepartment>(`${controller}/${id}`);

export const addDepartment = async (department: DepartmentAdd) => await http.post(controller, department);

export const updateDepartment = async (id: number, department: IDepartment) =>
  await http.put(`${controller}/${id}`, department);

export const deleteDepartment = async (id: number) => await http.delete(`${controller}/${id}`);
