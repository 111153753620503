import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Badge, Flex, List, Popover, Tabs, Typography, notification as notificationAntd } from 'antd';
import Link from 'antd/es/typography/Link';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { getNotifications, markAllNotificationsAsRead, markNotificationsAsRead } from 'src/apis/notification.api';
import Bell from 'src/assets/icons/bell.png';
import BusinessTripIcon from 'src/assets/icons/business-trip.png';
import Handover from 'src/assets/icons/handover.png';
import LeaveIcon from 'src/assets/icons/leave.png';
import MoneyIcon from 'src/assets/icons/money.png';
import OvertimeIcon from 'src/assets/icons/overtime.png';
import CartIcon from 'src/assets/icons/shopping-cart.png';
import TruckIcon from 'src/assets/icons/truck.png';
import Helpdesk from 'src/assets/images/help-desk.png';
import { NOTIFICATION_TYPE } from 'src/constants/notification';
import { AppContext } from 'src/contexts/app.context';
import { INotificationType } from 'src/types/helpdesk/notificationType';
import { INotification, NotificationParams } from 'src/types/notification.type';
import { getAccessTokenFromLS } from 'src/utils/auth';
import { getOrderString } from 'src/utils/utils';
import Icon from '../Icon';
import './Notification.scss';

const { Title, Text } = Typography;
dayjs.extend(relativeTime);

function NotificationJSX() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setTicketSelected } = useContext(AppContext);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [connection, setConnection] = useState<HubConnection | undefined>(undefined);
  const [notificationItems, setNotificationItems] = useState<INotification[]>([]);
  const [notificationParams, setNotificationParams] = useState<NotificationParams>({
    page: 1,
    pageSize: 10,
    orderBy: getOrderString('createdDate', 'descend')
  });

  useEffect(() => {
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
    } else {
      Notification.requestPermission();
    }
  }, []);

  // Call api
  const notificationsQuery = useQuery({
    queryKey: ['notifications', notificationParams],
    queryFn: () => getNotifications(notificationParams),
    staleTime: 60 * 1000
  });

  useEffect(() => {
    if (notificationsQuery.data?.data) {
      setNotificationItems((prev) => [...prev, ...notificationsQuery.data.data.results]);
    }
  }, [notificationsQuery.data?.data]);

  useEffect(() => {
    const notificationCount = notificationItems.filter((item) => item.isRead === false).length;
    setNotificationCount(notificationCount);
  }, [notificationItems]);

  const markNotificationsAsReadMutation = useMutation({
    mutationFn: (id?: string) => markNotificationsAsRead(id),
    onSuccess: (_, id) => {
      const notifications = notificationItems.map((item) => {
        if (item.id === id || !id) {
          item.isRead = true;
        }
        return item;
      });
      setNotificationItems(notifications);
      setNotificationCount(id ? notificationCount - 1 : 0);
    }
  });

  const markAllNotificationsAsReadMutation = useMutation({
    mutationFn: () => markAllNotificationsAsRead(),
    onSuccess: () => {
      const notifications = notificationItems.map((item) => {
        item.isRead = true;
        return item;
      });
      setNotificationItems(notifications);
      setNotificationCount(0);
    }
  });

  const handleLoadMore = () => {
    setNotificationParams((prev) => ({
      ...prev,
      page: prev.page + 1
    }));
  };

  const handleMarkAllRead = () => {
    markAllNotificationsAsReadMutation.mutate();
  };

  const handleViewNotification = useCallback(
    (item: INotification) => {
      !item.isRead && markNotificationsAsReadMutation.mutate(item.id);
      const obj: INotificationType | undefined = NOTIFICATION_TYPE.find((obj) => obj.key === item.type);
      const id = item.returnUrl;
      if (!obj?.returnUrl) return;
      else if (id) {
        setTicketSelected(id);
        return navigate(`${obj?.returnUrl}/${id}`);
      } else navigate(obj?.returnUrl);
    },
    [markNotificationsAsReadMutation, navigate, setTicketSelected]
  );

  const listNotification = () => (
    <InfiniteScroll
      className='notification'
      height={'50vh'}
      dataLength={notificationItems?.length}
      hasMore={Number(notificationsQuery.data?.data.pageNumber) < Number(notificationsQuery.data?.data.totalPages)}
      loader={false}
      next={handleLoadMore}
    >
      <List
        itemLayout='horizontal'
        className='notification-list'
        dataSource={notificationItems}
        loading={notificationsQuery.isFetching}
        renderItem={(item) => (
          <List.Item className='notification-item' onClick={() => handleViewNotification(item)}>
            <List.Item.Meta
              className='notification-content'
              avatar={<Icon url={renderAvatarTicket(item.type)} size={40} />}
              title={
                <Text className='notification-title'>{NOTIFICATION_TYPE.find((x) => x.key === item.type)?.title}</Text>
              }
              description={
                <Flex vertical>
                  <Text className='notification-message'>{item.message}</Text>
                  <Text className='notification-time' type='secondary'>
                    {dayjs(item.createdDate, 'YYYY-MM-DD HH:mm').fromNow()}
                  </Text>
                </Flex>
              }
            />
            {!item.isRead && <Badge color='hwb(205 6% 9%)' className='notification-status' />}
          </List.Item>
        )}
      />
    </InfiniteScroll>
  );

  const handleInvalidate = (key: string, queryClient: QueryClient, id: string) => {
    const [notificationType] = NOTIFICATION_TYPE.filter((item) => item.key === key);
    if (notificationType.key === 'TKAD') {
      queryClient.invalidateQueries({
        queryKey: ['ticket', id]
      });
      queryClient.invalidateQueries({
        queryKey: ['tickets']
      });
    } else {
      if (Array.isArray(notificationType.queryFn)) {
        notificationType.queryFn.forEach((item) => {
          queryClient.invalidateQueries({
            queryKey: [item]
          });
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: [notificationType.queryFn]
        });
      }
    }
  };

  const renderAvatarTicket = (type: string) => {
    const key = type.split('_')?.[0];
    switch (key) {
      case 'OT':
        return OvertimeIcon;
      case 'TRIP':
        return BusinessTripIcon;
      case 'AP':
        return MoneyIcon;
      case 'TRAN':
        return TruckIcon;
      case 'PR':
        return CartIcon;
      case 'HANDOVER':
        return Handover;
      case 'LEAVE':
        return LeaveIcon;
      default:
        return Helpdesk;
    }
  };

  const handleNotificationWindow = useCallback(
    (notification: INotification) => {
      if (Notification.permission === 'granted' || Notification.permission === 'default') {
        const obj: INotificationType | undefined = NOTIFICATION_TYPE.find((obj) => obj.key === notification.type);
        const id = notification.returnUrl;
        let notificationWindow: Notification;

        const icon = renderAvatarTicket(notification.type);

        notificationWindow = new Notification(String(obj?.title), {
          body: notification.message,
          icon: icon,
          dir: 'ltr'
        });

        notificationWindow.onclick = (event) => {
          event.preventDefault();
          let url = '';
          if (id) {
            setTicketSelected(id);
            url = `${obj?.returnUrl}/${id}`;
          } else {
            url = obj?.returnUrl ?? '';
          }
          navigate(url);
        };
      }
    },
    [setTicketSelected, navigate]
  );

  useEffect(() => {
    const notificationUrl =
      process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_NOTIFICATION_ENDPOINT_PROD
        : process.env.REACT_APP_NOTIFICATION_ENDPOINT;
    const accessToken = getAccessTokenFromLS();
    if (accessToken) {
      const connect = new HubConnectionBuilder()
        .withUrl(String(notificationUrl), {
          accessTokenFactory: () => accessToken
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.None)
        .build();

      setConnection(connect);
    }
  }, []);

  useEffect(() => {
    if (connection) {
      if (connection.state === 'Disconnected') {
        connection.start().then(() => {
          connection.on('ReceiveNotifications', (notification: INotification) => {
            if (notification.type !== 'TOPIC_MESSAGE') {
              setNotificationItems((prev) => {
                return [notification, ...prev];
              });

              setNotificationCount((prev) => prev + 1);
            }

            if (document.visibilityState !== 'visible') {
              handleNotificationWindow(notification);
            } else {
              console.warn('Block notification window');
            }

            handleInvalidate(notification.type, queryClient, notification.returnUrl);

            notificationAntd.info({
              message: <Text strong>Thông báo</Text>,
              description: notification.message,
              placement: 'topRight',
              style: { cursor: 'pointer' },
              onClick: () => {
                handleViewNotification(notification);
              }
            });
          });
        });
      }
    }
  }, [connection, t, queryClient, navigate, handleNotificationWindow, handleViewNotification]);

  return (
    <Popover
      placement='bottomLeft'
      rootClassName='header-right-notification'
      content={
        <Fragment>
          <Tabs
            defaultValue='all'
            items={[
              {
                key: 'all',
                label: <Title level={5}>Thông báo</Title>
              }
            ]}
            tabBarExtraContent={
              <Link disabled={notificationItems.filter((item) => !item.isRead).length <= 0} onClick={handleMarkAllRead}>
                {t('action.markAllAsRead')}
              </Link>
            }
          />
          {listNotification()}
        </Fragment>
      }
      trigger='click'
      arrow={false}
      overlayStyle={{
        width: 390
      }}
    >
      <Badge count={notificationCount} size='small' overflowCount={10}>
        <span className='notification-icon'>
          <Icon url={Bell} size={18} />
        </span>
      </Badge>
    </Popover>
  );
}

export default NotificationJSX;
