import type { FlatItem } from 'src/types/elements.type';
import { AppLicationInfoAdd, IApplicationInfo } from 'src/types/master_data/applicationInfo.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/application-infos';

export const getApplicationInfos = async (
  searchCondition: string | undefined,
  sequenceStatus: string,
  page: number,
  pageSize: number,
  orderBy?: string
) => {
  return await http.get<PagedList<IApplicationInfo>>(controller, {
    params: {
      name: searchCondition,
      sequenceStatus,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getApplicationInfo = async (id: number) => {
  return await http.get<IApplicationInfo>(`${controller}/${id}`);
};

export const addApplicationInfo = async (applicationInfo: AppLicationInfoAdd) => {
  return await http.post(controller, applicationInfo);
};

export const updateApplicationInfo = async (id: number, applicationInfo: AppLicationInfoAdd) => {
  return await http.put(`${controller}/${id}`, applicationInfo);
};

export const deleteApplicationInfo = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};

export const getApplicationInfosDropdown = async () => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
};
