import { CRUDPathSegmentEn } from '../../newFeatures/crudPathSegment.enum';

const productionPath = {
  productionManagement: '/erp/contract-execution/production',

  // Production order -Yêu cầu sản xuất
  productionOrder: '/erp/contract-execution/production/production-order',
  productionOrderDetail: '/erp/contract-execution/production/production-order' + CRUDPathSegmentEn.DETAIL,
  productionOrderDetailCode: '/erp/contract-execution/production/production-order' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  productionOrderAdd: '/erp/contract-execution/production/production-order' + CRUDPathSegmentEn.ADD,
  productionOrderEdit: '/erp/contract-execution/production/production-order' + CRUDPathSegmentEn.EDIT,

  // Production request - Lệnh sản xuất
  productionRequest: '/erp/contract-execution/production/production-request',
  productionRequestDetail: '/erp/contract-execution/production/production-request' + CRUDPathSegmentEn.DETAIL,
  productionRequestDetailCode:
    '/erp/contract-execution/production/production-request' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  productionRequestAdd: '/erp/contract-execution/production/production-request' + CRUDPathSegmentEn.ADD,
  productionRequestEdit: '/erp/contract-execution/production/production-request' + CRUDPathSegmentEn.EDIT,

  // Raw Mat BOM - BOM Nguyên vật liệu
  rawMatBom: '/erp/contract-execution/production/raw-mat-bom',
  rawMatBomDetail: '/erp/contract-execution/production/raw-mat-bom' + CRUDPathSegmentEn.DETAIL,
  rawMatBomDetailCode: '/erp/contract-execution/production/raw-mat-bom' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  rawMatBomAdd: '/erp/contract-execution/production/raw-mat-bom' + CRUDPathSegmentEn.ADD,
  rawMatBomEdit: '/erp/contract-execution/production/raw-mat-bom' + CRUDPathSegmentEn.EDIT,
  // Labor BOM - BOM Nhân công
  laborBom: '/erp/contract-execution/production/labor-bom',
  laborBomDetail: '/erp/contract-execution/production/labor-bom' + CRUDPathSegmentEn.DETAIL,
  laborBomDetailCode: '/erp/contract-execution/production/labor-bom' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  laborBomAdd: '/erp/contract-execution/production/labor-bom' + CRUDPathSegmentEn.ADD,
  laborBomEdit: '/erp/contract-execution/production/labor-bom' + CRUDPathSegmentEn.EDIT,
  // Labor BOM - BOM Nhân công
  equipmentBom: '/erp/contract-execution/production/equipment-bom',
  equipmentBomDetail: '/erp/contract-execution/production/equipment-bom' + CRUDPathSegmentEn.DETAIL,
  equipmentBomDetailCode: '/erp/contract-execution/production/equipment-bom' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  equipmentBomAdd: '/erp/contract-execution/production/equipment-bom' + CRUDPathSegmentEn.ADD,
  equipmentBomEdit: '/erp/contract-execution/production/equipment-bom' + CRUDPathSegmentEn.EDIT,

  // Delivery order - Yêu cầu giao hàng
  deliveryOrder: '/erp/contract-execution/production/delivery-order',
  deliveryOrderDetail: '/erp/contract-execution/production/delivery-order' + CRUDPathSegmentEn.DETAIL,
  deliveryOrderDetailCode: '/erp/contract-execution/production/delivery-order' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  deliveryOrderAdd: '/erp/contract-execution/production/delivery-order' + CRUDPathSegmentEn.ADD,
  deliveryOrderEdit: '/erp/contract-execution/production/delivery-order' + CRUDPathSegmentEn.EDIT,

  // Delivery request - Lệnh giao hàng
  deliveryRequest: '/erp/contract-execution/production/delivery-request',
  deliveryRequestDetail: '/erp/contract-execution/production/delivery-request' + CRUDPathSegmentEn.DETAIL,
  deliveryRequestDetailCode: '/erp/contract-execution/production/delivery-request' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  deliveryRequestAdd: '/erp/contract-execution/production/delivery-request' + CRUDPathSegmentEn.ADD,
  deliveryRequestEdit: '/erp/contract-execution/production/delivery-request' + CRUDPathSegmentEn.EDIT,

  // Acceptance minute - Biên bản Nghiệm thu thành phẩm
  acceptanceMinute: '/erp/contract-execution/production/acceptance-minute',
  acceptanceMinuteDetail: '/erp/contract-execution/production/acceptance-minute' + CRUDPathSegmentEn.DETAIL,
  acceptanceMinuteDetailCode: '/erp/contract-execution/production/acceptance-minute' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  acceptanceMinuteAdd: '/erp/contract-execution/production/acceptance-minute' + CRUDPathSegmentEn.ADD,
  acceptanceMinuteEdit: '/erp/contract-execution/production/acceptance-minute' + CRUDPathSegmentEn.EDIT,

  // Acceptance permission - Phân quyền Biên bản Nghiệm thu thành phẩm
  acceptancePermission: '/interactive/system-admin/acceptance-permission',
  acceptancePermissionDetail: '/interactive/system-admin/acceptance-permission' + CRUDPathSegmentEn.DETAIL,
  acceptancePermissionAdd: '/interactive/system-admin/acceptance-permission' + CRUDPathSegmentEn.ADD,
  acceptancePermissionEdit: '/interactive/system-admin/acceptance-permission' + CRUDPathSegmentEn.EDIT,

  //  Acceptance report - Báo cáo Nghiệm thu
  acceptanceReport: '/erp/contract-execution/production/acceptance-report'
};
export default productionPath;
