import { RangeDateTypeHr } from 'src/types/date.type';
import { ApprovalStatus } from 'src/types/helpdesk/ticket.type';
import {
  AdditionalWorkingTimePackageAdd,
  AdditionalWorkingTimePackageShort,
  IAdditionalWorkingTimePackage
} from 'src/types/hr_ticket/additionalWorkingTimePackage.type';
import { PagedList } from 'src/types/pagedList.type';
import { ITicketParams } from 'src/types/params.type';
import { http } from 'src/utils/http';

const controller = 'hr/additional-working-time-packages';

export const getAdditionalWorkingTimePackages = async (
  ticketStatusId: number | undefined,
  searchCondition: string | undefined,
  page: number,
  pageSize: number,
  fromDate?: Date,
  toDate?: Date,
  orderBy?: string
) => {
  return await http.get<PagedList<IAdditionalWorkingTimePackage>>(`${controller}`, {
    params: {
      ticketStatusId,
      searchCondition,
      fromDate,
      toDate,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getAdditionalWorkingTimePackagesApproval = async (params: ITicketParams) => {
  return await http.get<PagedList<IAdditionalWorkingTimePackage>>(`${controller}/waiting-approval`, {
    params: params
  });
};

export const getAdditionalWorkingTimePackage = async (id: number) => {
  return await http.get<IAdditionalWorkingTimePackage>(`${controller}/${id}`);
};

export const getAdditionalWorkingTimePackagesDropdown = async () => {
  return await http.get<AdditionalWorkingTimePackageShort[]>(`${controller}/dropdown`);
};

export const approvalAdditionalWorkingTimePackage = async (
  id: number,
  ticketIdsNotApproved: number[],
  step: number,
  approvalStatus: ApprovalStatus,
  note?: string
) =>
  await http.put(`${controller}/approval?id=${id}`, {
    ticketIdsNotApproved,
    step,
    approvalStatus,
    note: note
  });

export const cancelApprovalAdditionalWorkingTimePackages = async (id: string, step: number) =>
  await http.put(`${controller}/${id}/cancel-approval`, { step });

export const getAdditionalWorkingTimePackageWaitingProcessingCount = async (params: RangeDateTypeHr) => {
  return await http.get(`${controller}/count-ticket-waiting-processing`, {
    params: params
  });
};

export const getAdditionalWorkingTimePackageApprovalCount = async (params: RangeDateTypeHr) => {
  return await http.get(`hr/additional-working-time-packages/count-handle-packages`, {
    params: params
  });
};

export const addAdditionalWorkingTimePackage = async (
  additionalWorkingTimePackage: AdditionalWorkingTimePackageAdd
) => {
  return http.post<IAdditionalWorkingTimePackage>(controller, additionalWorkingTimePackage);
};

export const cancelAdditionalWorkingTimePackage = async (id: string | number) => {
  await http.put(`${controller}/${id}/cancel`);
};
