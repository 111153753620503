import { useQuery } from '@tanstack/react-query';
import { Select, SelectProps } from 'antd';
import { ReactNode } from 'react';
import { getTicketSolutionsDropdown } from 'src/apis/helpdesk/ticketSolution.api';

interface Props extends SelectProps {
  ticketId: string;
  placeholder?: ReactNode;
}

export default function SolutionTicketSelect({
  value,
  onChange,
  style,
  allowClear = true,
  ticketId,
  placeholder
}: Props) {
  const solutionsTicketDropdown = useQuery({
    queryKey: ['solutionsTicketDropdown', ticketId],
    queryFn: () => getTicketSolutionsDropdown(ticketId as string),
    staleTime: 60 * 1000,
    enabled: !!ticketId
  });

  return (
    <Select
      allowClear={allowClear}
      value={solutionsTicketDropdown.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={solutionsTicketDropdown.data?.data.map((data) => ({
        value: data.id,
        label: data.title
      }))}
      showSearch
      placeholder={placeholder}
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={solutionsTicketDropdown.isFetching}
    />
  );
}
