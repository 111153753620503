import axios, { AxiosError, AxiosInstance } from 'axios';
import { signinRedirect } from 'src/apis/auth.api';
import { HttpStatusCode } from 'src/constants/httpStatusCode.enum';
import { getAccessTokenFromLS } from './auth';
import { showNotification } from './notification';

class Http {
  instance: AxiosInstance;
  private accessToken: string;
  constructor(version = 'v1') {
    const baseURL =
      process.env.NODE_ENV === 'production'
        ? `${process.env.REACT_APP_API_ENDPOINT_PROD}/${version}`
        : `${process.env.REACT_APP_API_ENDPOINT}/${version}`;

    this.instance = axios.create({
      baseURL,
      timeout: 30000,
      headers: {
        'Content-Type': 'application/json'
      },
      transformRequest: [
        (data, headers) => {
          if (data instanceof FormData) {
            return data;
          }

          if (data && typeof data === 'object') {
            Object.keys(data).forEach((key) => {
              if (data[key] instanceof Date) {
                const userTimezoneOffset = data[key].getTimezoneOffset() * 60000;
                const dateWithoutOffset = new Date(data[key].getTime() - userTimezoneOffset);
                data[key] = dateWithoutOffset;
              }
            });
          }
          return JSON.stringify(data);
        }
      ]
    });
    this.accessToken = '';
    this.getAccessToken().then((token) => {
      this.accessToken = token;
    });
    this.instance.interceptors.request.use(
      async (config) => {
        const accessToken = await this.getAccessToken();
        if (config.headers) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error: AxiosError) => {
        if (error.response?.status === HttpStatusCode.Unauthorized) {
          const profile = localStorage.getItem('profile');
          const pathname = JSON.parse(profile as string)?.state?.pathname;
          await signinRedirect(
            window.location.pathname?.includes('/signin-oidc') ? pathname : window.location.pathname
          );
        } else if (error.response?.status === HttpStatusCode.BadRequest) {
          showNotification(error.response.data as string, 'error');
        } else {
          const data: any | undefined = error.response?.data;
          const message = data?.message || error?.message;
          console.warn(message, 'error');
        }
        return Promise.reject(error);
      }
    );
  }
  async getAccessToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.accessToken) {
        resolve(this.accessToken);
      } else {
        try {
          const token = getAccessTokenFromLS();
          resolve(token);
        } catch (error) {
          reject('Error when getting access token from local storage');
        }
      }
    });
  }
}

const http = new Http().instance;
const httpV2 = new Http('v2').instance;

export { http, httpV2 };
