import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getProjectsDropdown } from 'src/apis/hr_ticket/projectCode.api';
import type { FlatItem, SelectProps } from 'src/types/elements.type';

export default function ProjectSelect({
  value,
  onChange,
  style,
  allowClear = true,
  placeholder,
  disabled,
  returnValueType
}: SelectProps) {
  const projectsDropdownQuery = useQuery({
    queryKey: ['projectsDropdown'],
    queryFn: () => getProjectsDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      disabled={disabled}
      allowClear={allowClear}
      value={projectsDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={projectsDropdownQuery.data?.data
        .sort((a: FlatItem, b: FlatItem) => a.code?.localeCompare(b.code))
        .map((item) => {
          return {
            value:
              returnValueType === 'code' ? item.code : returnValueType === 'object' ? JSON.stringify(item) : item.id,
            label: item.code
          };
        })}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      placeholder={placeholder}
      loading={projectsDropdownQuery.isFetching}
    />
  );
}
