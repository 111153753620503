import { useQuery } from '@tanstack/react-query';
import { Button, Flex, Select } from 'antd';
import { getEmployeesDropdownByDepartments } from 'src/apis/master_data/employee.api';

interface Props {
  value?: string[];
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  onChange?: (value: string[]) => void;
  departmentId?: string | string[];
  mode?: 'multiple' | 'tags';
  maxTagCount?: number | 'responsive';
  quickSelectAll?: boolean | undefined;
  onSelectAll?: (value: string[]) => void;
  returnValueType?: 'id' | 'code' | 'object';
}

export default function EmployeeMultipleSelectV2({
  value,
  style,
  allowClear,
  onChange,
  departmentId,
  mode = 'tags',
  maxTagCount,
  quickSelectAll,
  onSelectAll,
  returnValueType
}: Props) {
  const employeesDropdownQuery = useQuery({
    queryKey: ['employeesDropdownV3', departmentId],
    queryFn: () => getEmployeesDropdownByDepartments(departmentId ? String(departmentId) : undefined),
    staleTime: 60 * 1000
  });

  const handleSelectAll = () => {
    const selectedList = employeesDropdownQuery.data?.data?.map((x) => {
      if (!returnValueType) return String(x.id);
      if (returnValueType === 'object') {
        return JSON.stringify(x);
      } else {
        return x[returnValueType];
      }
    }) as string[];

    if (onSelectAll) onSelectAll(selectedList);
  };

  return (
    <Flex style={{ flex: 1 }}>
      <Select
        allowClear={allowClear}
        mode={mode}
        maxTagCount={maxTagCount}
        value={employeesDropdownQuery.isFetching ? undefined : value}
        style={style}
        onChange={onChange}
        options={employeesDropdownQuery.data?.data.map((data) => ({
          value: returnValueType
            ? returnValueType === 'object'
              ? JSON.stringify(data)
              : data[returnValueType]
            : data.id,
          label: `${data.code} - ${data.fullName}`
        }))}
        showSearch
        filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
        loading={employeesDropdownQuery.isFetching}
      />
      {quickSelectAll && onSelectAll && (
        <Button
          type='link'
          style={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: '100%'
          }}
          onClick={handleSelectAll}
        >
          Chọn tất cả
        </Button>
      )}
    </Flex>
  );
}
