import type { HierarchicalItem, HierarchicalItemMenu, MenuItem, TreeItem } from 'src/types/elements.type';
import { v4 as uuid } from 'uuid';

type HierarchyType<T> = {
  id: number;
  parentId?: number;
  parent: T;
  level?: number;
  children: T[] | undefined;
};

export function buildHierarchyMenu(
  flatArray: HierarchicalItemMenu[] | undefined,
  currentResourceId?: number
): MenuItem[] | undefined {
  if (flatArray) {
    const map = new Map<string, HierarchicalItemMenu>();
    const roots: HierarchicalItemMenu[] = [];

    flatArray.forEach((node) => {
      const { id, parentId, ...rest } = node;
      const newNode: HierarchicalItemMenu = { id, ...rest };

      map.set(id.toString(), newNode);

      if (!parentId || currentResourceId === parentId) {
        roots.push(newNode);
      } else {
        const parentNode = map.get(parentId.toString());

        if (parentNode) {
          if (!parentNode.children) {
            parentNode.children = [];
          }

          parentNode.children.push(newNode);
        }
      }
    });
    const menuItems: MenuItem[] = roots?.sort((a, b) => a?.sort - b?.sort)?.map((item) => convertToMenuItem(item));
    return menuItems;
  }
}

export function buildHierarchySelect(flatArray: HierarchicalItem[] | undefined): TreeItem[] | undefined {
  if (flatArray) {
    const map = new Map<string, HierarchicalItem>();
    const roots: HierarchicalItem[] = [];

    flatArray?.forEach((node) => {
      const { id, parentId, ...rest } = node;
      const newNode: HierarchicalItem = { id, ...rest };

      map.set(id.toString(), newNode);

      if (!parentId) {
        roots.push(newNode);
      } else {
        const parentNode = map.get(parentId.toString());

        if (parentNode) {
          if (!parentNode.children) {
            parentNode.children = [];
          }

          parentNode.children.push(newNode);
        }
      }
    });
    const treeItems: TreeItem[] = roots.map((item) => convertToTreeItem(item));
    return treeItems;
  }
}

function convertToTreeItem(item: HierarchicalItem): TreeItem {
  const treeItem: TreeItem = {
    key: item.id,
    value: item.id.toString(),
    title: item.name,
    children: []
  };

  if (item.children && item.children.length > 0) {
    treeItem.children = item.children.map((child) => convertToTreeItem(child));
  }

  return treeItem;
}

function convertToMenuItem(item: HierarchicalItemMenu): MenuItem {
  const menuItem: MenuItem = {
    key: item.path || `undefined ${uuid()}`,
    // label: React.createElement(Text, { children: item.name, title: item.name }),
    label: item.name,
    disabled: !(item.children.length > 0) && !item.path,
    // icon: item?.icon,
    children: []
  };

  if (item.children && item.children.length > 0) {
    menuItem.children = item.children?.sort((a, b) => a?.sort - b?.sort)?.map((child) => convertToMenuItem(child));
  } else {
    menuItem.children = undefined;
  }

  return menuItem;
}

// export function buildDepartmentHierarchy(flatArray: IDepartment[] | undefined): IDepartment[] | undefined {
//   if (flatArray) {
//     const map = new Map<string, IDepartment>();
//     const roots: IDepartment[] = [];

//     flatArray?.forEach((node) => {
//       const { id, parentId, ...rest } = node;
//       const newNode: IDepartment = { id, ...rest };

//       map.set(id.toString(), newNode);

//       if (!parentId) {
//         roots.push(newNode);
//       } else {
//         const parentNode = map.get(parentId.toString());

//         if (parentNode) {
//           if (!parentNode.children) {
//             parentNode.children = [];
//           }
//           newNode.parent = parentNode;
//           parentNode.children.push(newNode);
//         }
//       }
//     });
//     const result = removeEmptyChildrenDepartment(roots);
//     return result;
//   }
// }

// function removeEmptyChildrenDepartment(nodes: IDepartment[] | undefined): IDepartment[] | undefined {
//   if (!nodes) {
//     return undefined;
//   }

//   return nodes.map((node) => {
//     if (node.children && node.children.length > 0) {
//       node.children = removeEmptyChildrenDepartment(node.children);
//     } else {
//       node.children = undefined;
//     }
//     return node;
//   });
// }

// export function buildTicketCategoryGroupHierarchy(
//   flatArray: ITicketCategoryGroup[] | undefined
// ): ITicketCategoryGroup[] | undefined {
//   if (flatArray) {
//     const map = new Map<string, ITicketCategoryGroup>();
//     const roots: ITicketCategoryGroup[] = [];

//     flatArray?.forEach((node) => {
//       const { id, parentId, ...rest } = node;
//       const newNode: ITicketCategoryGroup = { id, ...rest };

//       map.set(id.toString(), newNode);

//       if (!parentId) {
//         roots.push(newNode);
//       } else {
//         const parentNode = map.get(parentId.toString());

//         if (parentNode) {
//           if (!parentNode.children) {
//             parentNode.children = [];
//           }
//           newNode.parent = parentNode;
//           parentNode.children.push(newNode);
//         }
//       }
//     });
//     const result = removeEmptyChildrenTicketCategoryGroup(roots);
//     return result;
//   }
// }

// function removeEmptyChildrenTicketCategoryGroup(
//   nodes: ITicketCategoryGroup[] | undefined
// ): ITicketCategoryGroup[] | undefined {
//   if (!nodes) {
//     return undefined;
//   }

//   return nodes.map((node) => {
//     if (node.children && node.children.length > 0) {
//       node.children = removeEmptyChildrenTicketCategoryGroup(node.children);
//     } else {
//       node.children = undefined;
//     }
//     return node;
//   });
// }

// export function buildItemGroupHierarchy(flatArray: IItemGroup[] | undefined): IItemGroup[] | undefined {
//   if (flatArray) {
//     const map = new Map<string, IItemGroup>();
//     const roots: IItemGroup[] = [];

//     flatArray?.forEach((node) => {
//       const { id, parentId, ...rest } = node;
//       const newNode: IItemGroup = { id, ...rest };

//       map.set(id.toString(), newNode);

//       if (!parentId) {
//         roots.push(newNode);
//       } else {
//         const parentNode = map.get(parentId.toString());

//         if (parentNode) {
//           if (!parentNode.children) {
//             parentNode.children = [];
//           }
//           newNode.parent = parentNode;
//           parentNode.children.push(newNode);
//         }
//       }
//     });
//     const result = removeEmptyChildrenItemGroup(roots);
//     return result;
//   }
// }

// function removeEmptyChildrenItemGroup(nodes: IItemGroup[] | undefined): IItemGroup[] | undefined {
//   if (!nodes) {
//     return undefined;
//   }

//   return nodes.map((node) => {
//     if (node.children && node.children.length > 0) {
//       node.children = removeEmptyChildrenItemGroup(node.children);
//     } else {
//       node.children = undefined;
//     }
//     return node;
//   });
// }

export function buildHierarchy<T extends HierarchyType<T>>(
  flatArray: T[] | undefined,
  currentResourceId?: number
): T[] | undefined {
  if (flatArray) {
    const map = new Map<string, T>();
    const roots: T[] = [];

    flatArray?.forEach((node: T) => {
      const { id, parentId, children, ...rest } = node;
      const newNode: T = { id, ...rest } as T;

      map.set(id.toString(), newNode);

      if (!parentId || currentResourceId === parentId) {
        newNode.level = 1;
        roots.push(newNode);
      } else {
        const parentNode = map.get(parentId.toString());

        if (parentNode) {
          if (!parentNode.children) {
            parentNode.children = [];
          }
          newNode.parent = parentNode;
          newNode.level = (parentNode.level || 0) + 1;
          parentNode.children.push(newNode);
        }
      }
    });
    const result = removeEmptyChildren(roots);

    return result;
  }
}

function removeEmptyChildren<T extends HierarchyType<T>>(nodes: T[] | undefined): T[] | undefined {
  if (!nodes) {
    return undefined;
  }

  return nodes.map((node) => {
    if (node.children && node.children.length > 0) {
      node.children = removeEmptyChildren(node.children);
    } else {
      node.children = undefined;
    }
    return node;
  });
}

export function findMenuItem(menuItems: MenuItem[], key: string): MenuItem | undefined {
  for (const item of menuItems) {
    if (item.key === key) {
      return item;
    }
    if (item.children) {
      const found = findMenuItem(item.children, key);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
}
