import { useQuery } from '@tanstack/react-query';
import { Select, type SelectProps } from 'antd';
import { getProcessesDropdown } from 'src/apis/item_master_data/process.api';

type Props = SelectProps & {
  valueType?: 'id' | 'code';
};

export default function ProcessSelect({
  value,
  onChange,
  style,
  allowClear = true,
  valueType = 'id',
  ...props
}: Props) {
  const processesDropdownQuery = useQuery({
    queryKey: ['processesDropdown'],
    queryFn: () => getProcessesDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={processesDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={processesDropdownQuery.data?.data.map((item) => ({
        value: item[valueType],
        label: item.name,
        data: item
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={processesDropdownQuery.isFetching}
      {...props}
    />
  );
}
