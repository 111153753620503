import { useQuery } from '@tanstack/react-query';
import { Select, SelectProps } from 'antd';
import { getApplicationInfosDropdown } from 'src/apis/master_data/applicationInfo.api';

export default function ApplicationInfoSelectV2({ value, onChange, style, allowClear }: SelectProps) {
  const applicationInfosDropdownV2Query = useQuery({
    queryKey: ['applicationInfosDropdownV2'],
    queryFn: () => getApplicationInfosDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={applicationInfosDropdownV2Query.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={applicationInfosDropdownV2Query.data?.data.map((data) => ({
        value: JSON.stringify(data),
        label: data.name
      }))}
      showSearch
      loading={applicationInfosDropdownV2Query.isFetching}
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
    />
  );
}
