import { useQuery } from '@tanstack/react-query';
import { Flex, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import { getShifts } from 'src/apis/master_data/shift.api';
import { totalOfRangeTime } from 'src/utils/utils';
const { Text } = Typography;

interface Props {
  value?: string;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

export default function ShiftSelect({ value, style, disabled, allowClear, onChange }: Props) {
  const shiftDropdownQuery = useQuery({
    queryKey: ['shiftDropdown'],
    queryFn: () => getShifts(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={shiftDropdownQuery.isFetching ? undefined : value}
      style={style}
      disabled={disabled}
      onChange={onChange}
      options={shiftDropdownQuery.data?.data.map((data) => ({
        value: data.id,
        label: (
          <Flex justify='space-between' align='center'>
            <Flex vertical>
              <Text strong>{`${data?.code} - ${data?.name}`}</Text>
              <Flex gap={6}>
                <Text>{`${dayjs(data?.from, 'HH:mm').format('HH:mm')} - ${dayjs(data?.to, 'HH:mm').format(
                  'HH:mm'
                )}`}</Text>
                <Text type='secondary' style={{ fontSize: 13 }}>
                  (Nghỉ giải lao {totalOfRangeTime(data.breakTimeFrom, data.breakTimeTo)} tiếng)
                </Text>
              </Flex>
            </Flex>
          </Flex>
        )
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={shiftDropdownQuery.isFetching}
    />
  );
}
