import { useQuery } from '@tanstack/react-query';
import { Flex, Select, Typography } from 'antd';
import React from 'react';
import { getAdditionalWorkingTimePackagesDropdown } from 'src/apis/hr_ticket/additionalWorkingTimePackage.api';
import { formatDate } from 'src/utils/utils';

const { Text } = Typography;

interface Props {
  className?: string;
  value?: number;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  onChange?: (value: number) => void;
  disabled?: boolean;
}

export default function AdditionalWorkingTimePackageSelect({
  className,
  value,
  style,
  allowClear,
  onChange,
  disabled
}: Props) {
  const additionalWorkingTimePackagesDropdownQuery = useQuery({
    queryKey: ['additionalWorkingTimePackagesDropdown'],
    queryFn: () => getAdditionalWorkingTimePackagesDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      className={className}
      allowClear={allowClear}
      value={additionalWorkingTimePackagesDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      disabled={disabled}
      options={additionalWorkingTimePackagesDropdownQuery.data?.data?.map((item) => ({
        value: item.id,
        label: (
          <Flex vertical>
            <Text>{item.code}</Text>
            <Flex gap={4}>
              <Text type='secondary'>
                {formatDate(item.fromDate)} - {formatDate(item.toDate)}
              </Text>
              {item.note && <Text type='secondary'>({item.note})</Text>}
            </Flex>
          </Flex>
        )
      }))}
      showSearch
      loading={additionalWorkingTimePackagesDropdownQuery.isFetching}
    />
  );
}
