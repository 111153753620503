import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getRequestTypesDropdown } from 'src/apis/hr_ticket/transportationTicket.api';
import { SelectProps } from 'src/types/elements.type';

export default function RequestTypeSelect({ value, style, allowClear = true, onChange, placeholder }: SelectProps) {
  const requestTypesQuery = useQuery({
    queryKey: ['requestTypesDropdown'],
    queryFn: () => getRequestTypesDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={requestTypesQuery.isFetching ? undefined : value}
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      options={requestTypesQuery.data?.data.map((data) => ({
        value: data.id,
        label: data.name
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={requestTypesQuery.isFetching}
    />
  );
}
