import dayjs from 'dayjs';
import { Dispatch, SetStateAction, createContext, useState } from 'react';
import { HrConfirmTypeEn } from 'src/constants/hrConfirmTypeEn.enum';
import { RangeDateType } from 'src/types/date.type';
import { TicketStatus } from 'src/types/helpdesk/ticket.type';
import type { TableParams } from 'src/types/tableParams.type';

interface TicketContextInterface {
  sortField: string | undefined;
  setSortField: Dispatch<SetStateAction<string | undefined>>;
  sortOrientation: string | undefined;
  setSortOrientation: Dispatch<SetStateAction<string | undefined>>;
  ticketCategoryId: number | undefined;
  setTicketCategoryId: Dispatch<SetStateAction<number | undefined>>;
  approvalStatus: number | undefined;
  setApprovalStatus: Dispatch<SetStateAction<number | undefined>>;
  ticketStatusId: number | undefined;
  setTicketStatusId: Dispatch<SetStateAction<number | undefined>>;
  confirmType: HrConfirmTypeEn | undefined | undefined;
  setConfirmType: Dispatch<SetStateAction<HrConfirmTypeEn | undefined | undefined>>;
  processingDepartmentId: number | undefined;
  setProcessingDepartmentId: Dispatch<SetStateAction<number | undefined>>;
  requestDepartmentId: number | undefined;
  setRequestDepartmentId: Dispatch<SetStateAction<number | undefined>>;
  departmentId: number | undefined;
  setDepartmentId: Dispatch<SetStateAction<number | undefined>>;
  sequenceApprovalId: string[] | undefined;
  setSequenceApprovalId: Dispatch<SetStateAction<string[] | undefined>>;
  leaveTypeId: number | undefined;
  setLeaveTypeId: Dispatch<SetStateAction<number | undefined>>;
  isConfirm: HrConfirmTypeEn | undefined;
  setIsConfirm: Dispatch<SetStateAction<HrConfirmTypeEn | undefined>>;
  isSupervisorConfirmed: HrConfirmTypeEn | undefined;
  setIsSupervisorConfirmed: Dispatch<SetStateAction<HrConfirmTypeEn | undefined>>;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  rangeDate: RangeDateType;
  setRangeDate: Dispatch<SetStateAction<RangeDateType>>;
  tableParams: TableParams;
  setTableParams: Dispatch<SetStateAction<TableParams>>;
  invalidKey: string;
  setInvalidKey: Dispatch<SetStateAction<string>>;
}

const initialTicketContext: TicketContextInterface = {
  sortField: 'createdDate',
  setSortField: () => null,
  approvalStatus: undefined,
  setApprovalStatus: () => null,
  sortOrientation: 'desc',
  setSortOrientation: () => null,
  ticketCategoryId: undefined,
  setTicketCategoryId: () => null,
  ticketStatusId: undefined,
  setTicketStatusId: () => null,
  confirmType: undefined,
  setConfirmType: () => null,
  processingDepartmentId: undefined,
  setProcessingDepartmentId: () => null,
  requestDepartmentId: undefined,
  setRequestDepartmentId: () => null,
  departmentId: undefined,
  setDepartmentId: () => null,
  sequenceApprovalId: undefined,
  setSequenceApprovalId: () => null,
  leaveTypeId: undefined,
  setLeaveTypeId: () => null,
  isConfirm: undefined,
  setIsConfirm: () => null,
  isSupervisorConfirmed: undefined,
  setIsSupervisorConfirmed: () => null,
  searchTerm: '',
  setSearchTerm: () => null,
  rangeDate: {
    fromDate: new Date(dayjs(dayjs().add(-30, 'day')).format('YYYY-MM-DD')),
    toDate: new Date(dayjs(dayjs().add(30, 'day')).format('YYYY-MM-DD'))
  },
  setRangeDate: () => null,
  tableParams: {
    pagination: {
      current: 1,
      pageSize: 20,
      showSizeChanger: false,
      pageSizeOptions: [10]
    }
  },
  setTableParams: () => null,
  invalidKey: '',
  setInvalidKey: () => null
};

export const TicketContext = createContext<TicketContextInterface>(initialTicketContext);

export const TicketProvider = ({ children }: { children: React.ReactNode }) => {
  const [sortField, setSortField] = useState<string | undefined>(initialTicketContext.sortField);
  const [searchTerm, setSearchTerm] = useState<string>(initialTicketContext.searchTerm);
  const [rangeDate, setRangeDate] = useState<RangeDateType>(initialTicketContext.rangeDate);
  const [sortOrientation, setSortOrientation] = useState<string | undefined>(initialTicketContext.sortOrientation);
  const [ticketStatusId, setTicketStatusId] = useState<TicketStatus | undefined>(initialTicketContext.ticketStatusId);
  const [approvalStatus, setApprovalStatus] = useState<number | undefined>(initialTicketContext.approvalStatus);
  const [confirmType, setConfirmType] = useState<HrConfirmTypeEn | undefined>(initialTicketContext.confirmType);
  const [ticketCategoryId, setTicketCategoryId] = useState<number | undefined>(initialTicketContext.ticketCategoryId);
  const [processingDepartmentId, setProcessingDepartmentId] = useState<number | undefined>(
    initialTicketContext.processingDepartmentId
  );
  const [requestDepartmentId, setRequestDepartmentId] = useState<number | undefined>(
    initialTicketContext.processingDepartmentId
  );
  const [departmentId, setDepartmentId] = useState<number | undefined>(initialTicketContext.departmentId);
  const [sequenceApprovalId, setSequenceApprovalId] = useState<string[] | undefined>(
    initialTicketContext.sequenceApprovalId
  );
  const [leaveTypeId, setLeaveTypeId] = useState<number | undefined>(initialTicketContext.leaveTypeId);
  const [isSupervisorConfirmed, setIsSupervisorConfirmed] = useState<HrConfirmTypeEn | undefined>(
    initialTicketContext.isSupervisorConfirmed
  );
  const [isConfirm, setIsConfirm] = useState<HrConfirmTypeEn | undefined>(initialTicketContext.isConfirm);

  const [tableParams, setTableParams] = useState<TableParams>(initialTicketContext.tableParams);
  const [invalidKey, setInvalidKey] = useState<string>(initialTicketContext.invalidKey);

  return (
    <TicketContext.Provider
      value={{
        sortField,
        setSortField,
        rangeDate,
        setRangeDate,
        searchTerm,
        setSearchTerm,
        approvalStatus,
        setApprovalStatus,
        ticketStatusId,
        setTicketStatusId,
        ticketCategoryId,
        setTicketCategoryId,
        sortOrientation,
        setSortOrientation,
        requestDepartmentId,
        setRequestDepartmentId,
        processingDepartmentId,
        setProcessingDepartmentId,
        departmentId,
        setDepartmentId,
        sequenceApprovalId,
        setSequenceApprovalId,
        leaveTypeId,
        setLeaveTypeId,
        isConfirm,
        setIsConfirm,
        isSupervisorConfirmed,
        setIsSupervisorConfirmed,
        confirmType,
        setConfirmType,
        tableParams,
        setTableParams,
        invalidKey,
        setInvalidKey
      }}
    >
      {children}
    </TicketContext.Provider>
  );
};
