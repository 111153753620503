import { useQuery } from '@tanstack/react-query';
import { Select, Typography } from 'antd';
import { getTargetsDropdown } from 'src/apis/erp/target.api';
import type { SelectProps } from 'src/types/elements.type';
const { Text } = Typography;

export default function TargetSelect({
  value,
  onChange,
  style,
  allowClear = true,
  placeholder,
  disabled,
  returnValueType
}: SelectProps) {
  const targetsDropdownQuery = useQuery({
    queryKey: ['targetsDropdown'],
    queryFn: () => getTargetsDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      disabled={disabled}
      allowClear={allowClear}
      value={targetsDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={targetsDropdownQuery.data?.data.map((item) => {
        return {
          value: returnValueType === 'code' ? item.code : returnValueType === 'object' ? JSON.stringify(item) : item.id,
          label: (
            <Text title={`${item.code} - ${item.name}`}>
              {item.code} - {item.name}
            </Text>
          )
        };
      })}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      placeholder={placeholder}
      loading={targetsDropdownQuery.isFetching}
    />
  );
}
