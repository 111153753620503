import { useQuery } from '@tanstack/react-query';
import { Flex, Select, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { getExpenseTypesDropdown } from 'src/apis/hr_ticket/expenseType.api';
import type { FlatItem, SelectProps } from 'src/types/elements.type';

const { Text } = Typography;

export default function ExpenseTypeSelect({ value, style, allowClear = true, onChange, placeholder }: SelectProps) {
  const [options, setOptions] = useState<FlatItem[]>([]);

  const expenseTypesDropdownQuery = useQuery({
    queryKey: ['expenseTypesDropdown'],
    queryFn: () => getExpenseTypesDropdown(),
    staleTime: 60 * 1000
  });

  useEffect(() => {
    if (expenseTypesDropdownQuery.data) {
      setOptions(expenseTypesDropdownQuery?.data?.data || []);
    }
  }, [expenseTypesDropdownQuery.data]);

  const handleSearch = (term: string) => {
    if (!value) {
      setOptions(expenseTypesDropdownQuery.data?.data || []);
    } else {
      const searchResults = options.filter((option) => {
        return (
          (option?.code?.toString() ?? '').toLowerCase()?.includes(term.toLowerCase()) ||
          (option?.name?.toString() ?? '').toLowerCase()?.includes(term.toLowerCase())
        );
      });

      setOptions(searchResults);
    }
  };

  return (
    <Select
      allowClear={allowClear}
      value={expenseTypesDropdownQuery.isFetching ? undefined : value}
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      optionLabelProp='code'
      options={options.map((item) => ({
        value: JSON.stringify(item),
        label: (
          <Flex vertical>
            <Text strong>{item.code}</Text>
            <Text type='secondary' style={{ fontSize: 12 }}>
              {item.name}
            </Text>
          </Flex>
        ),
        code: item.code
      }))}
      showSearch
      onSearch={handleSearch}
      // filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={expenseTypesDropdownQuery.isFetching}
    />
  );
}
