import { useContext } from 'react';
import { PermissionEn } from 'src/constants/permission.enum';
import { AppContext } from 'src/contexts/app.context';
import { IResourceFullAccessDetail } from 'src/types/master_data/userResource.type';
import { useBasePath } from './useBasePath';

function usePermission({ path }: { path?: string }) {
  const { resources } = useContext(AppContext);
  const basePath = useBasePath();

  const getListPermission = () => {
    let _path = path ?? basePath;
    const permissions = resources?.find((x) => x?.path === _path)?.permissions;
    return permissions;
  };

  const listPermission = getListPermission();

  const isRead = !!listPermission?.includes(PermissionEn.read);
  const isViewDetail = !!listPermission?.includes(PermissionEn.viewDetail);
  const isCreate = !!listPermission?.includes(PermissionEn.create);
  const isUpdate = !!listPermission?.includes(PermissionEn.update);
  const isDelete = !!listPermission?.includes(PermissionEn.delete);
  const isApprove = !!listPermission?.includes(PermissionEn.approve);
  const isReject = !!listPermission?.includes(PermissionEn.reject);
  const isApprove_solution = !!listPermission?.includes(PermissionEn.approve_solution);
  const isAssign = !!listPermission?.includes(PermissionEn.assign);
  const isCreate_leave_ticket = !!listPermission?.includes(PermissionEn.create_leave_ticket);
  const isAdmin = !!listPermission?.includes(PermissionEn.admin);
  const isGetQr = !!listPermission?.includes(PermissionEn.get_qr);
  const isConfigField = !!listPermission?.includes(PermissionEn.config_field);
  const isConfirmTransportation = !!listPermission?.includes(PermissionEn.confirm_transportation);

  return {
    isRead,
    isViewDetail,
    isCreate,
    isUpdate,
    isDelete,
    isApprove,
    isReject,
    isApprove_solution,
    isAssign,
    isCreate_leave_ticket,
    isAdmin,
    isGetQr,
    isConfigField,
    isConfirmTransportation
  };
}

export function usePermissionNew(paths?: string[], resourcesParam?: IResourceFullAccessDetail[]) {
  const { resources } = useContext(AppContext);
  const basePath = useBasePath();

  const getPermissions = () => {
    let result = [];
    const currPagePermission = (resourcesParam ?? resources)?.find((x) => x?.path === basePath);

    if (currPagePermission) result?.push(currPagePermission);

    paths?.forEach((path) => {
      let _path = path ?? basePath;

      const permissions = (resourcesParam ?? resources)?.find((x) => x?.path === _path);
      if (permissions) result?.push(permissions);
    });

    return result;
  };

  const permissions = getPermissions();

  return permissions;
}

export default usePermission;
