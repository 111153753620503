import { theme } from 'antd';
import Footer from 'src/components/v1/Footer';
import { Header } from 'src/components/v2/Header';
import './MainLayout.scss';

interface Props {
  children?: React.ReactNode;
  disableFooter?: boolean;
}

export default function MainLayout({ children, disableFooter }: Props) {
  const {
    token: { colorBgContainer }
  } = theme.useToken();

  return (
    <div
      className='layout-container'
      style={{
        width: '100%',
        backgroundColor: 'var(--background-color-app)'
      }}
    >
      <Header />
      {children}
      {!disableFooter && (
        <Footer
          colorBgContainer={colorBgContainer}
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
            height: 'var(--footer-height)',
            textAlign: 'center',
            lineHeight: 'var(--footer-height)',
            fontSize: '12px',
            padding: '0',
            borderTop: 'var(--divider-size) solid var(--divider-color)'
          }}
        />
      )}
    </div>
  );
}
