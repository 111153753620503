import Layout, { Content } from 'antd/es/layout/layout';
import Header from 'src/components/NewFeatures/Header';
import Sidebar from 'src/components/NewFeatures/Sidebar';
import Footer from 'src/components/v1/Footer';
import styles from './MainLayout.module.scss';
import SubHeader from 'src/components/NewFeatures/SubHeader/SubHeader';
interface Props {
  children?: React.ReactNode;
  hasSidebar?: boolean;
}

const sidebarWidth: number = 360;

export default function MainLayout({ children, hasSidebar = true }: Props) {
  return (
    <main>
      <Header />
      <Layout>
        <Sidebar isDefaultCollapsed={!hasSidebar} sidebarWidth={sidebarWidth} />
        {children && (
          <Content className={styles.main}>
            {/* <Breadcrumb /> */}
            <SubHeader />
            <div className={styles.container}>{children}</div>
          </Content>
        )}
      </Layout>
      <Footer
        colorBgContainer={'var(--divider-color)'}
        style={{
          position: 'fixed',
          bottom: 0,
          left: sidebarWidth,
          right: 0,
          zIndex: 1,
          height: 'var(--footer-height)',
          textAlign: 'center',
          lineHeight: 'var(--footer-height)',
          fontSize: '12px',
          padding: '0',
          borderTop: 'var(--divider-size) solid var(--divider-color)'
        }}
      />
    </main>
  );
}
